<div class="row align-items-center mt-3">
  <div class="col-sm-4 " style="text-align: right;">
    <label class="mb-0" data-cy="ser-dep-status-label">
      Status
      <span class="required" aria-required="true">*</span>
      <span data-cy="ser-dep-status-tooltip">
        <i class="fa fa-info-circle" (click)="showInfo()" aria-hidden="true"></i>
      </span>
    </label>
  </div>
  <div class="col-sm-8" [formGroup]="group">
    <p-dropdown
      [filter]="true"
      [style]="{ width: '100%' }"
      [options]="statusDropDownModel"
      [(ngModel)]="selectedStatus"
      name="serviceStatus"
      [formControlName]="'serviceStatus'"
      data-cy="ser-dep-status"
    ></p-dropdown>

    <div *ngIf="submitted">
      <span *ngIf="submitted && statusControl.hasError('required')" class="text-danger" data-cy="ser-dep-status-error">
        A valid status is required.
      </span>
    </div>
  </div>
</div>
