import { select } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, SubscriptionLike as ISubscription } from 'rxjs';
import { TaskResult } from '../../../../api.client';
import { ServiceDeploymentStateConstant } from '../../../actions/servicedeployment.constants';
import { ServiceDeploymentAndServiceTaskParams } from '../../../models/servicedeployment-and-servicetaskparams';
import { ServiceTaskResultsService } from '../services/task-results.service';

@Component({
  selector: 'service-task-results',
  templateUrl: 'task-results.component.html',
  styleUrls: ['./task-results.component.scss'],
})
export class ServiceTaskResultsComponent implements OnInit, OnDestroy {
  public serviceDeploymentRefId: string;
  public taskRefId: string;
  public taskLocationRefId: string;
  public serviceDeploymentRefNumber: string;
  public serviceDeploymentTaskRefNumber: string;
  public serviceTaskLocationRefNumber: string;
  public accountId: number;
  public fieldGroupName: string;
  public serviceDeploymentName: string;
  public serviceTaskName: string;
  private routeSubscription: ISubscription;
  private dataSubscription: ISubscription;
  private serviceDeploymentTaskRefNumberParamsSubscription: ISubscription;
  private addTaskLocationSubscription: ISubscription;
  public loading = false;
  public showData = false;
  public data: TaskResult[];
  public questionData: any[];

  @select([ServiceDeploymentStateConstant.FilterStateName])
  private readonly serviceDeploymentTaskLocation: Observable<ServiceDeploymentAndServiceTaskParams>;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _taskResultService: ServiceTaskResultsService,
  ) {}

  ngOnInit() {
    this.serviceDeploymentTaskRefNumberParamsSubscription = this.serviceDeploymentTaskLocation.subscribe(
      (data: ServiceDeploymentAndServiceTaskParams) => {
        this.serviceDeploymentRefNumber = data.serviceDeploymentRefNumber;
        this.serviceDeploymentTaskRefNumber = data.serviceDeploymentTaskRefNumber;
        this.serviceTaskLocationRefNumber = data.serviceTaskLocationRefNumber;
        this.fieldGroupName = data.fieldGroupName;
        this.serviceDeploymentName = data.serviceDeploymentName;
        this.serviceTaskName = data.serviceTaskName;
        this.accountId = data.accountId;
      },
    );

    if (this.fieldGroupName == undefined || this.fieldGroupName === '' || this.fieldGroupName == null) {
      this._router.navigate(['/servicedeployments/data']);
    }

    this.routeSubscription = this._route.params.subscribe((params: Params) => {
      this.serviceDeploymentRefId = params['refid'];
      this.taskRefId = params['taskId'];
      this.taskLocationRefId = params['taskLocationId'];
      this.pullData();
    });
  }

  pullData() {
    this.loading = true;
    this.dataSubscription = this._taskResultService
      .getServiceTaskResult(this.accountId, this.serviceDeploymentRefId, this.taskRefId, this.taskLocationRefId)
      .subscribe((data: any) => {
        this.data = data;

        if (this.data.length > 0) {
          this.showData = true;
        }
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.dataSubscription && this.dataSubscription.unsubscribe();
    this.serviceDeploymentTaskRefNumberParamsSubscription &&
      this.serviceDeploymentTaskRefNumberParamsSubscription.unsubscribe();
  }

  public GotoTaskLocaiton() {
    this._router.navigate(['/servicedeployments', this.serviceDeploymentRefId, 'task', this.taskRefId]);
  }
}
