import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PC, PermissionCollection } from '@volt/shared/services/permissions.service';
import { RETAILER_CONFIG, RetailerConfigType } from '../../../../retailer-config';
import { InvitedAccountService } from '../../services/invitedsupplier.service';
import { RegistrationModalComponent } from '../registration-modal/registration-modal.component';
import { InvitedAccountModel, UserInformation, FieldGroupTypeViewModel, CourseModel } from '../../../api.client';
import { RoleConstant } from '../../../auth/shared/roles.constants';
import { MonitoringService } from '@volt/shared/services/monitoring.service';
import { AuthService } from '../../../auth';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'invite-supplier-form',
  templateUrl: './invitedsupplier.component.html',
  styleUrls: ['./invitedsupplier.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvitedSupplierComponent implements OnInit {
  public inviteSupplierGroup: UntypedFormGroup;
  public errorMessage: string;
  public working = false;
  public error: string = null;
  public fieldTypeDropModel = [{ label: '--Select--', value: null }];
  public certificationDropModel = [{ label: '--Select--', value: null }];
  public invitedEmail: string;
  public accountNumber: string;
  public emailExist = true;
  public emailMsg = false;
  public vendorIdExist = true;
  public vendorIdMsg = false;
  public vendorIdExistSupplier = false;
  public vendorIdSupplierMsg: string;
  public loading = false;
  public currentUser: UserInformation;
  public role: string;
  public showAnote = false;
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  private _fieldGroupTypes: FieldGroupTypeViewModel[];
  private _certifications: CourseModel[];

  constructor(
    private _fb: UntypedFormBuilder,
    private _InvitedAccountService: InvitedAccountService,
    private _modal: NgbModal,
    private readonly monitoringService: MonitoringService,
    private readonly _authService: AuthService,
    private _dynamicDialogService: DynamicDialogService,
    private readonly _router: Router,
    @Inject(PC) public pc: PermissionCollection,
    @Inject(RETAILER_CONFIG) public retailerConfig: RetailerConfigType,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getFieldTypeDropDownList();
    this.getCertificationDropDownList();
    this.currentUser = this._authService.getCurrentUser();
    this.role = this.currentUser.roles;
    this.checkRole(this.role);
  }

  public submitInviteSupplier() {
    this.loading = true;
    const invitedAccount = new InvitedAccountModel();

    invitedAccount.invitedCompany = this.inviteSupplierGroup.get('invitedCompany').value.toString().trim();
    invitedAccount.invitedName = this.inviteSupplierGroup.get('invitedName').value;
    invitedAccount.invitedEmail = this.inviteSupplierGroup.get('invitedEmail').value;
    invitedAccount.accountNumber = this.inviteSupplierGroup.get('accountNumber').value;
    invitedAccount.invitedNote =
      this.inviteSupplierGroup.get('invitedNotes').value === ''
        ? null
        : this.inviteSupplierGroup.get('invitedNotes').value;

    invitedAccount.fieldGroupType =
      this.inviteSupplierGroup.get('fieldGroupType').value === ''
        ? null
        : this.inviteSupplierGroup.get('fieldGroupType').value;

    if (invitedAccount.fieldGroupType) {
      const selectedTemplate = this._fieldGroupTypes.find(f => f.name === invitedAccount.fieldGroupType);

      if (selectedTemplate && selectedTemplate.templateAccount) {
        invitedAccount.printBadge = selectedTemplate.templateAccount.printBadge;
      }
    }

    invitedAccount.invitedCourse =
      this.inviteSupplierGroup.get('certification').value === ''
        ? null
        : this.inviteSupplierGroup.get('certification').value;

    invitedAccount.invitedAccountId = 0;
    invitedAccount.accountId = 0;
    invitedAccount.shipDirectToRep = 0;
    invitedAccount.retailLink = false;

    if (this.showAnote) {
      invitedAccount.aNote =
        this.inviteSupplierGroup.get('empNotes').value === '' ? null : this.inviteSupplierGroup.get('empNotes').value;
    } else {
      invitedAccount.aNote = '';
    }

    invitedAccount.aname = invitedAccount.invitedCompany;

    this._InvitedAccountService.saveInvitedAccount(invitedAccount).subscribe((response: any) => {
      if (response) {
        this.loading = false;
        this._dynamicDialogService.open(RegistrationModalComponent, {
          data: { message: 'An email has been sent to the supplier contact to complete the registration process.' },
        });
      }
      this.inviteSupplierGroup.reset();
    });
  }

  public get invitedCompanyControl(): UntypedFormControl {
    if (!this.inviteSupplierGroup) {
      return;
    }

    return <UntypedFormControl>this.inviteSupplierGroup.get('invitedCompany');
  }

  public get invitedNameControl(): UntypedFormControl {
    if (!this.inviteSupplierGroup) {
      return;
    }

    return <UntypedFormControl>this.inviteSupplierGroup.get('invitedName');
  }

  public get invitedEmailControl(): UntypedFormControl {
    if (!this.inviteSupplierGroup) {
      return;
    }

    return <UntypedFormControl>this.inviteSupplierGroup.get('invitedEmail');
  }

  public get accountNumberControl(): UntypedFormControl {
    if (!this.inviteSupplierGroup) {
      return;
    }
    return <UntypedFormControl>this.inviteSupplierGroup.get('accountNumber');
  }

  public getFieldTypeDropDownList() {
    this._InvitedAccountService.getFieldTypeDropDownList().subscribe(
      (data: FieldGroupTypeViewModel[]) => {
        if (data) {
          this._fieldGroupTypes = data;
          this.fieldTypeDropModel.push(
            ...data.map(x => ({
              label: x.description + ' - ' + x.name,
              value: x.name,
            })),
          );
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public getCertificationDropDownList() {
    const accountId = 5582;
    this._InvitedAccountService.getCertificationDropDownList().subscribe(
      (data: CourseModel[]) => {
        if (data) {
          this._certifications = data;
          this.certificationDropModel.push(
            ...data.map(x => ({
              label: x.courseName,
              value: x.courseName,
            })),
          );
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  isNumberKey(evt) {
    // tslint:disable-next-line:prefer-const
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  public checkEmailAlreadyExist() {
    if (this.invitedEmail == undefined) {
      this.invitedEmail = '';
    }

    this._InvitedAccountService.checkEmailAlreadyExist(this.invitedEmail).subscribe(
      (data: any) => {
        this.emailExist = data;
        if (data) {
          this.emailMsg = true;
        } else {
          this.emailMsg = false;
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  public checkAccountNoAlreadyExist() {
    if (this.accountNumber === '' || this.accountNumber === undefined || this.accountNumber === null) {
      this.vendorIdExistSupplier = false;
      this.vendorIdSupplierMsg = '';
      return (this.vendorIdMsg = false);
    }

    this._InvitedAccountService.checkAccountNoAlreadyExist(this.accountNumber).subscribe(
      (data: any) => {
        this.vendorIdExist = data;

        if (data === true) {
          this.vendorIdMsg = true;
        } else {
          this.vendorIdMsg = false;
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );

    this._InvitedAccountService.GetSupplierByNo(this.accountNumber).subscribe(
      (data: any) => {
        if (data != null) {
          this.vendorIdSupplierMsg = data.supplierName;
          this.vendorIdExistSupplier = true;
        } else {
          this.vendorIdSupplierMsg = '';
          this.vendorIdExistSupplier = false;
        }
      },
      (err: any) => {
        console.error(err);
        this.monitoringService.logApiException(err);
      },
    );
  }

  /**
   * Return to the previous page (WDE-6850)
   * which can only be /registration/account-list
   */
  public cancel() {
    this._router.navigate(['/registration/account-list']);
  }

  public checkRole(roleName: any) {
    if (roleName === RoleConstant.SystemAdministrator || roleName === RoleConstant.RetailerAdmin) {
      this.showAnote = true;
    } else {
      this.showAnote = false;
    }
  }

  private initForm() {
    this.inviteSupplierGroup = this._fb.group({
      invitedCompany: this._fb.control('', Validators.required),
      invitedEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      invitedName: this._fb.control('', Validators.required),
      invitedNotes: this._fb.control(''),
      empNotes: this._fb.control(''),
      accountNumber: this._fb.control(''),
      fieldGroupType: this._fb.control(''),
      certification: this._fb.control(''),
    });
  }

  updateInvitedCert(event: any) {
    if (event && event.value) {
      const selectedTemplate = this._fieldGroupTypes.find(f => f.name === event.value);

      if (
        selectedTemplate &&
        selectedTemplate.templateAccount &&
        selectedTemplate.templateAccount.certificationIds &&
        selectedTemplate.templateAccount.certificationIds.length > 0
      ) {
        const cert = this._certifications.find(
          f => f.courseId === selectedTemplate.templateAccount.certificationIds[0],
        );
        this.inviteSupplierGroup.get('certification').setValue(cert.courseName);
      }
    }
  }
}
