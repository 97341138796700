import { NgRedux, select } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import isNil from 'lodash-es/isNil';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import { defer, Observable, of as observableOf, SubscriptionLike as ISubscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  DropDownModel,
  LocationSummary,
  Log,
  TaskLocationClient,
  TaskLocationInput,
  UserInformation
} from '../../../api.client';
import { VoltValidators } from '@volt/shared/volt-validators';
import { IAppState } from '../../../store';
import { Status } from '@volt/shared/constants/status.constant';

import {
  AddServiceDeploymentTaskLocationStateConstant,
  ServiceDeploymentStateConstant,
} from '../../actions/servicedeployment.constants';
import { AddTaskLocation } from '../../models/addTaskLocation';
import { ServiceDeploymentAndServiceTaskParams } from '../../models/servicedeployment-and-servicetaskparams';
import { DataPassingService } from '../../services/data-passing.service';
import { ServicedeploymentTaskLocationService } from '../locations/services/servicedeployment-task-location.service';
import { RequireStatusModalComponent } from '../require-status-modal/require-status-modal.component';
import { ServiceDeploymentsService } from '../servicedeployments/services/service-deployments.service';
import {
  IServiceDeploymentLogStatus,
  ViewLogStatusModalComponent,
} from '../view-log-status-modal/view-log-status-modal.component';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { CommonUtils } from '@volt/shared/utils/common.utils';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { AuthService } from '../../../auth';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'add-service-deployment-task-location',
  templateUrl: './addservicedeployment-task-location.component.html',
  styleUrls: ['./addservicedeployment-task-location.component.scss'],
})
export class AddServicedeploymentTaskLocationComponent implements OnInit, OnDestroy {
  public addServiceTaskLocationFormGroup: UntypedFormGroup;
  public statusDropDownModel: DropDownModel[];
  public submitted = false;
  public invalidControl: string[] = [];
  public storeNumber: string;
  public startDate: Date;
  public endDate: Date;
  public serviceStatus: string;
  public status: string;
  public loading = false;
  public storeNumberLocationTimeZoneExist = false;
  public filteredEmpList: DropDownModel[] = [];
  public employeeValues: DropDownModel[] = [];
  public currentaddedServiceDeploymentTaskLocation: AddTaskLocation;
  public currentUser: UserInformation;
  public accountId;

  private addTaskLocationSubscription: ISubscription;
  private routeSubscription: ISubscription;
  private serviceDeploymentRefNumberAndTaskRefNumberSubscription: ISubscription;
  private serviceDeploymentRefId;
  private taskRefId;
  private serviceDeploymentRefNumber;
  public taskLocationReferenceNumber: string;
  public taskRefNumber;
  public fieldGroupName: string;
  public statusType: string;
  public taskName: string;
  public storeNumberExist = false;
  public storeNumberMsg = false;
  public taskLocationRefNumberExist = false;
  public taskLocationRefNumberMsg = false;
  public locationTimeZone: string;
  public minDate: Date;
  public serviceTaskName: string;
  public showEndDate: boolean;
  public taskLocationId: string;
  public serviceTaskLocationEmpMsg = false;
  public serviceDeploymentDateMsg = false;

  public selectedStatus;
  public selectedemployees;

  canCreate = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Create);
  canEdit = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Update);

  @select([AddServiceDeploymentTaskLocationStateConstant.FilterStateName])
  private readonly addServiceDeploymentTaskLocation: Observable<AddTaskLocation>;

  @select([ServiceDeploymentStateConstant.FilterStateName])
  private readonly serviceDeploymentTask: Observable<ServiceDeploymentAndServiceTaskParams>;
  isEdit: boolean;

  constructor(
    private _serviceDeploymentService: ServiceDeploymentsService,
    private _serviceDeployTaskLocation: ServicedeploymentTaskLocationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private _appState: NgRedux<IAppState>,
    private _dataPassingService: DataPassingService,
    private _taskLocationClient: TaskLocationClient,
    private readonly permissionsService: PermissionsService,
    private readonly _authService: AuthService,
    private _dynamicDialogService: DynamicDialogService,
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this._route.params.subscribe((params: Params) => {
      this.serviceDeploymentRefId = params['serviceDeploymentRefId'];
      this.taskRefId = params['taskRefId'];
    });

    this.serviceDeploymentRefNumberAndTaskRefNumberSubscription = this.serviceDeploymentTask.subscribe(
      (data: ServiceDeploymentAndServiceTaskParams) => {
        this.serviceDeploymentRefNumber = data.serviceDeploymentRefNumber;
        this.taskRefNumber = data.serviceDeploymentTaskRefNumber;
        this.fieldGroupName = data.fieldGroupName;
        this.accountId = data.accountId;
        this.serviceTaskName = data.serviceTaskName;
        this.statusType = data.status;
      },
    );

    if (this.accountId == undefined || this.accountId === '' || this.accountId == null) {
      this._router.navigate(['/servicedeployments/data']);
    }

    this.addTaskLocationSubscription = this.addServiceDeploymentTaskLocation.subscribe(
      (data: AddTaskLocation) => {
        this.isEdit = data.edit === true;
        this.currentaddedServiceDeploymentTaskLocation = data;
        this.taskLocationId = data.id;

        if (this.currentaddedServiceDeploymentTaskLocation.number !== null) {
          this.getLocationTimeZoneByNumber(this.currentaddedServiceDeploymentTaskLocation.number);
        }

        if (this.currentaddedServiceDeploymentTaskLocation.status == null) {
          this.selectedStatus = Status.Active.toString();
        } else {
          this.selectedStatus =
            Status[this.capitalizeFirstLetter(this.currentaddedServiceDeploymentTaskLocation.status)];
        }
      },
      (err: any) => {
        console.error(err);
      },
    );

    this.populateEmplployees();

    this.initForm();
    this.getStatusDropDownList();

    this.currentUser = this._authService.getCurrentUser();
  }

  private populateEmplployees() {
    if (this.currentaddedServiceDeploymentTaskLocation.employeeNumbers != null) {
      this.selectedemployees = [];

      for (let i = 0; i < this.currentaddedServiceDeploymentTaskLocation.employeeNumbers.length; i++) {
        const emp = new DropDownModel();
        emp.label = this.currentaddedServiceDeploymentTaskLocation.employeeNumbers[i];
        emp.value = this.currentaddedServiceDeploymentTaskLocation.employeeNumbers[i].split(',')[0];
        this.selectedemployees.push(emp);
      }
    }
  }

  public capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  // This method is used to check store number already exist in TaskLocation
  public checkStoreNumberAlreadyExist(storeNumber: string) {
    this.loading = true;

    if (!isNil(storeNumber) && storeNumber !== '' && !isNil(this.accountId)) {
      this._serviceDeployTaskLocation
        .checkStoreNumberAlreadyExist(
          storeNumber,
          this.accountId,
          this.serviceDeploymentRefNumber,
          this.taskRefNumber,
          this.taskLocationId,
        )
        .subscribe(
          isExist => {
            this.storeNumberExist = isExist;
            this.storeNumberMsg = !!isExist;
          },
          (err: any) => {
            console.error(err);
          },
        );
    }

    this.getLocationTimeZoneByNumber(storeNumber);
  }

  // This method is used to get Location by Number
  public getLocationTimeZoneByNumber(storeNumber: string) {
    if (!isNil(storeNumber) && storeNumber !== '') {
      storeNumber = storeNumber.trim();

      this._serviceDeployTaskLocation.getLocationTimeZoneByNumber(storeNumber).subscribe(
        (data: LocationSummary) => {
          this.loading = false;

          if (data != null) {
            this.locationTimeZone = data.timeZone?.code;

            this.storeNumberLocationTimeZoneExist =
              this.locationTimeZone === '' || this.locationTimeZone === null || this.locationTimeZone === undefined;
          } else {
            this.storeNumberLocationTimeZoneExist = true;
          }
        },
        (err: any) => {
          console.error(err);
        },
      );
    } else {
      this.loading = false;
    }
  }

  // This method is used to check Task Location Reference number already exist in TaskLocation
  public checkTaskLocationRefNumberAlreadyExist(taskLocationRefNumber: string) {
    if (!isNil(taskLocationRefNumber) && taskLocationRefNumber !== '' && !isNil(this.accountId)) {
      taskLocationRefNumber = taskLocationRefNumber.trim();

      this._serviceDeployTaskLocation
        .checkTaskLocationRefNumberAlreadyExist(
          taskLocationRefNumber,
          this.accountId,
          this.serviceDeploymentRefNumber,
          this.taskRefNumber,
        )
        .subscribe(
          (data: any) => {
            this.taskLocationRefNumberExist = data;
            this.taskLocationRefNumberMsg = !!data;
          },
          (err: any) => {
            console.error(err);
          },
        );
    }
  }

  // set task end date greater than task start date
  public setEndDate(minDate: Date) {
    this.minDate = minDate;
    this.showEndDate = false;
    this._dataPassingService.passServiceDeploymentEndDate(this.showEndDate);
  }

  public submitServiceDeploymentTaskLocation() {
    const checkResponse = this.checkControlValidation();

    if (!checkResponse) {
      this.submitted = true;
      return;
    }

    this.taskLocationReferenceNumber = this.addServiceTaskLocationFormGroup.get('locationRefNumber').value.trim();

    const serviceDeploymentTaskLocation = Object.assign({}, this.currentaddedServiceDeploymentTaskLocation, {
      number: this.addServiceTaskLocationFormGroup.get('storeNumber').value.trim(),

      referenceNumber: this.addServiceTaskLocationFormGroup.get('locationRefNumber').value.trim(),

      employeeNumbers: this.addServiceTaskLocationFormGroup.get('employeeNo').value,

      start: this.addServiceTaskLocationFormGroup.get('startDate').value,

      end: this.addServiceTaskLocationFormGroup.get('endDate').value,

      status: this.addServiceTaskLocationFormGroup.get('serviceStatus').value,
    });

    if (
      new Date(this.addServiceTaskLocationFormGroup.get('endDate').value) <
      new Date(this.addServiceTaskLocationFormGroup.get('startDate').value)
    ) {
      this.serviceDeploymentDateMsg = true;
      const checkDate = this.checkControlValidation();
      if (!checkDate) {
        return;
      }
    }

    if (this.statusType === 'inactive') {
      const checkName = this.checkValidationForStatus();
      if (!checkName) {
        return;
      }
    }

    this.convertToStoreTimeZone(serviceDeploymentTaskLocation);

    this.loading = true;

    this.employeeValues = serviceDeploymentTaskLocation.employeeNumbers;
    serviceDeploymentTaskLocation.employeeNumbers = [];

    if (this.employeeValues) {
      for (let i = 0; i < this.employeeValues.length; i++) {
        const employee = this.employeeValues[i].value.trim();
        serviceDeploymentTaskLocation.employeeNumbers.push(employee);
      }
    }

    defer(() =>
      !serviceDeploymentTaskLocation.id
        ? this._taskLocationClient.addLocation(
            this.serviceDeploymentRefNumber,
            this.accountId,
            this.taskRefNumber,
            serviceDeploymentTaskLocation,
          )
        : this._taskLocationClient.updateLocation(
            this.serviceDeploymentRefNumber,
            this.accountId,
            this.taskRefNumber,
            serviceDeploymentTaskLocation.id,
            serviceDeploymentTaskLocation,
          ),
    )
      .pipe(
        catchError(err => {
          return observableOf(err);
        }),
      )
      .subscribe((response: Log) => {
        this.loading = false;
        this.locationTimeZone = null;

        this._dynamicDialogService.open(ViewLogStatusModalComponent, {
          data: {
            status: 'Pending',
            serviceDeploymentStatusName: 'Task Location Status',
            serviceDeploymentReferenceNumber: 'Task Location Reference #',
            transactionId: response.transactionId,
            accountId: this.accountId,
            referenceNumber: this.taskLocationReferenceNumber,
            serviceDeploymentRefId: this.serviceDeploymentRefId,
            taskRefId: this.taskRefId,
            showImportTaskLocation: false,
          } as IServiceDeploymentLogStatus,
        });
      });
  }

  private convertToStoreTimeZone(location: TaskLocationInput) {
    // create string representations of the dates that intentionally don't include the timezone
    const startStr = moment(location.start).format('YYYY-MM-DDTHH:mm:ss.SSS');
    const endStr = moment(location.end).format('YYYY-MM-DDTHH:mm:ss.SSS');

    location.start = moment.tz(startStr, this.locationTimeZone);
    location.end = moment.tz(endStr, this.locationTimeZone);

    function serializeMomentToJson() {
      return this.format();
    }

    location.start.toJSON = serializeMomentToJson;
    location.end.toJSON = serializeMomentToJson;
  }

  public getStatusDropDownList() {
    this._serviceDeploymentService.getStatusList().subscribe(
      (data: any) => {
        this.statusDropDownModel = data;

        if (this.currentaddedServiceDeploymentTaskLocation.status == null) {
          this.selectedStatus = Status.Active.toString();
        } else {
          this.selectedStatus =
            '' + Status[this.capitalizeFirstLetter(this.currentaddedServiceDeploymentTaskLocation.status)];
        }
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  public setEndDateMsg(event: any) {
    this.serviceDeploymentDateMsg = false;
  }

  public getEmployeeListByFilter(event) {
    if (event.query.length >= 2) {
      const searchFilterText = event.query;
      this.serviceTaskLocationEmpMsg = false;
      this._serviceDeployTaskLocation.getEmployeeListByFilter(searchFilterText, this.accountId).subscribe(
        (data: DropDownModel[]) => {
          this.filteredEmpList = data;

          if (data.findIndex(dropDownModel => dropDownModel.value.toLowerCase() === event.query.toLowerCase()) === -1) {
            this.filteredEmpList.push(
              new DropDownModel({
                label: searchFilterText,
                value: searchFilterText,
              }),
            );
          }

          if (this.filteredEmpList.length === 0) {
            this.serviceTaskLocationEmpMsg = true;
          } else {
            this.serviceTaskLocationEmpMsg = false;
          }
        },
        (err: any) => {
          console.error(err);
        },
      );
    } else {
      this.serviceTaskLocationEmpMsg = false;
      this.filteredEmpList = [new DropDownModel({ label: event.query, value: event.query })];
    }
  }

  public formReset() {
    this.addServiceTaskLocationFormGroup.reset();
    this._router.navigate(['/servicedeployments', this.serviceDeploymentRefId, 'task', this.taskRefId]);
  }

  public checkControlValidation() {
    this.invalidControl = [];
    this.checkValidationForServiceDeploymentTaskLocation();

    return this.invalidControl.length <= 0;
  }

  public checkValidationForStatus() {
    this._dynamicDialogService.open(RequireStatusModalComponent, {
      data: {
        message: 'Task location cannot be created for an  inactive task.',
      },
    });
    return false;
  }

  public checkValidationForServiceDeploymentTaskLocation() {
    if (this.addServiceTaskLocationFormGroup.invalid) {
      this.invalidControl.push('Invalid');
    } else if (this.storeNumberExist === true) {
      this.invalidControl.push('Store Number Already Exist');
    } else if (this.storeNumberLocationTimeZoneExist === true) {
      this.invalidControl.push('Location Time Zone does not exit');
    } else if (this.taskLocationRefNumberExist === true) {
      this.invalidControl.push('Task Location Reference Number Already Exist');
    } else if (this.serviceDeploymentDateMsg === true) {
      this.invalidControl.push('End date cannot be less than start date');
    }
  }

  private initForm() {
    this.addServiceTaskLocationFormGroup = this._fb.group({
      employeeNo: this._fb.control(''),
      storeNumber: this._fb.control(this.currentaddedServiceDeploymentTaskLocation.number, Validators.required),
      locationRefNumber: this._fb.control(
        this.currentaddedServiceDeploymentTaskLocation.referenceNumber,
        Validators.compose([Validators.required, Validators.pattern(/^[\w\.\-\:]*$/)]),
      ),
      startDate: this._fb.control('', Validators.required),
      endDate: this._fb.control('', [Validators.required, VoltValidators.ensureValidDateRange()]),
      serviceStatus: this._fb.control(''),
    });

    if (this.currentaddedServiceDeploymentTaskLocation) {
      if (this.currentaddedServiceDeploymentTaskLocation.edit && !this.canEdit) {
        CommonUtils.disableControls(this.addServiceTaskLocationFormGroup.controls);
      } else if (this.currentaddedServiceDeploymentTaskLocation.add && !this.canCreate) {
        this._router.navigate(['../data'], {
          relativeTo: this._route,
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.addTaskLocationSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
    this.serviceDeploymentRefNumberAndTaskRefNumberSubscription.unsubscribe();
  }
}
