import { Component, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { serviceDeploymentsReportSelector } from '../../../reducers/service-deployments-report.reducer';
import { ServiceDeploymentsService } from '../../servicedeployments/services/service-deployments.service';
import { TableDisplay } from '../../table-display/table-display.component';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FileResponse, GenericTableReportOfServiceDeploymentTaskLocationViewModel } from '../../../../api.client';
import { ServicedeploymentTaskLocationService } from '../services/servicedeployment-task-location.service';
import { LazyLoadEvent } from 'primeng/api';
import { SubscriptionLike as ISubscription, Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import {
  AddServiceDeploymentTaskLocationStateConstant,
  ServiceDeploymentStateConstant,
} from '../../../actions/servicedeployment.constants';
import { DataPassingService } from '../../../services/data-passing.service';
import { ServicedeploymnetTaskSearchParameters } from '../../../models/servicedeploymnet-task-search-parameters';
import { ServiceDeploymentAndServiceTaskParams } from '../../../models/servicedeployment-and-servicetaskparams';
import { CommonUtils } from '../../../../shared/utils/common.utils';

import { ServiceTaskLocationRetrievalClient } from '../../../../api.client';
import { AddTaskLocation } from '../../../models/addTaskLocation';
import { AddServicedeploymentTaskLocationReduxService } from '../../addservicedeployments-task-location/services/add-servicedeploymenttasklocation.reudx.service';
import { ServicedeploymentReduxService } from '../../../services/servicedeployment.redux.service';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import * as moment from 'moment';

@Component({
  selector: 'servicedeployment-task-location',
  templateUrl: 'servicedeployment-task-location.component.html',
  styleUrls: ['./servicedeployment-task-location.component.scss'],
})
export class ServicedeploymentTaskLocationComponent extends TableDisplay implements OnInit, OnDestroy {
  public serviceDeploymentRefId: string;
  public taskRefId: string;
  public isLocationComponent: true;
  public serviceDeploymentRefNumber: string;
  public serviceDeploymentTaskRefNumber: string;
  public fieldGroupName: string;
  public serviceDeploymentName: string;
  public serviceTaskName: string;
  public statusType: string;
  public accountId: number;
  public showDateFilter: boolean;
  private routeSubscription: ISubscription;
  private dataSubscription: ISubscription;
  private serviceDeploymentTaskRefNumberParamsSubscription: ISubscription;
  private addTaskLocationSubscription: ISubscription;
  public currentaddedServiceDeploymentTaskLocation: AddTaskLocation;
  public status;
  public minDate: Date;
  public showEndDate: boolean;

  canCreate = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Create);
  canEdit = this.permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Update);

  @select([ServiceDeploymentStateConstant.FilterStateName])
  private readonly serviceDeploymentTaskLocation: Observable<ServiceDeploymentAndServiceTaskParams>;

  @select([AddServiceDeploymentTaskLocationStateConstant.FilterStateName])
  private readonly taskLocation: Observable<AddTaskLocation>;

  @select(serviceDeploymentsReportSelector)
  private readonly serviceDeploymentsReport$: Observable<ServiceDeploymentReportParams>;

  constructor(
    private _route: ActivatedRoute,
    private _serviceDeploymentTaskLocationService: ServicedeploymentTaskLocationService,
    private _dataPassingService: DataPassingService,
    private _servicedeploymentReduxService: ServicedeploymentReduxService,
    private _router: Router,
    private _serviceDeploymentTaskLocationClient: ServiceTaskLocationRetrievalClient,
    private _taskLocationReduxService: AddServicedeploymentTaskLocationReduxService,
    private _serviceDeploymentsService: ServiceDeploymentsService,
    private readonly permissionsService: PermissionsService,
  ) {
    super(_serviceDeploymentsService);
  }

  ngOnInit() {
    this.showDateFilter = true;
    this.searchBy = 'Search By Store Number';
    this.isLocationComponent = true;

    this.serviceDeploymentsReport$.pipe(take(1)).subscribe(filters => {
      this.servicedeployemntfilterParams = filters;

      this.serviceDeploymentTaskRefNumberParamsSubscription = this.serviceDeploymentTaskLocation.subscribe(
        (data: ServiceDeploymentAndServiceTaskParams) => {
          this.serviceDeploymentRefNumber = data.serviceDeploymentRefNumber;
          this.serviceDeploymentTaskRefNumber = data.serviceDeploymentTaskRefNumber;
          this.fieldGroupName = data.fieldGroupName;
          this.serviceDeploymentName = data.serviceDeploymentName;
          this.serviceTaskName = data.serviceTaskName;
          this.statusType = data.status;
          this.accountId = data.accountId;
        },
      );

      if (this.fieldGroupName == undefined || this.fieldGroupName === '' || this.fieldGroupName == null) {
        this._router.navigate(['/servicedeployments/data']);
      }

      this.routeSubscription = this._route.params.subscribe((params: Params) => {
        this.serviceDeploymentRefId = params['refid'];
        this.taskRefId = params['taskId'];
        this.servicedeployemntfilterParams = Object.assign(this.servicedeployemntfilterParams, {
          serviceDeploymentRefId: this.serviceDeploymentRefId,
          serviceDeploymentTaskRefId: this.taskRefId,
        });
      });

      this._dataPassingService.advancedSearchParams.subscribe(servicedeploymentTaskLocationSearchParameters => {
        if (servicedeploymentTaskLocationSearchParameters) {
          const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
            serviceDeploymnetTaskLocationSearchParameters: servicedeploymentTaskLocationSearchParameters,
          });
          this.pullData(updateFilter);
        }
      });

      this.addTaskLocationSubscription = this.taskLocation.subscribe(
        (data: AddTaskLocation) => {
          this.currentaddedServiceDeploymentTaskLocation = data;
        },
        (err: any) => {
          console.error(err);
        },
      );
    });
  }

  public refresh() {
    this.servicedeployemntfilterParams.sortColumn = '-start';
    this.pullData(this.servicedeployemntfilterParams);
  }

  pullData($event: ServiceDeploymentReportParams) {
    this.loading = true;
    this.dataSubscription = this._serviceDeploymentTaskLocationService
      .getServiceDeploymentTaskLocation($event)
      .subscribe((data: GenericTableReportOfServiceDeploymentTaskLocationViewModel) => {
        this.cols = data.columnList;
        this.data = data.results;
        this.totalRecords = data.totalCount;
        this.loading = false;
      });
  }

  sortdata(event: LazyLoadEvent) {
    this.serviceDeploymentsReport$.pipe(take(1)).subscribe(filters => {
      this.servicedeployemntfilterParams = filters;
      if (event.sortField == undefined) event.sortField = '-start';

      let sortFieldwithOrdering = '';
      if (event.sortOrder === -1) {
        sortFieldwithOrdering = sortFieldwithOrdering.concat('-').concat(event.sortField);
      } else {
        sortFieldwithOrdering = sortFieldwithOrdering.concat(event.sortField);
      }

      const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
        sortColumn: sortFieldwithOrdering,
      });
      this.pullData(updateFilter);
    });
  }

  ngOnDestroy() {
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.dataSubscription && this.dataSubscription.unsubscribe();
    this.serviceDeploymentTaskRefNumberParamsSubscription &&
      this.serviceDeploymentTaskRefNumberParamsSubscription.unsubscribe();
  }

  public searchStartDate(startDate: Date) {
    this.minDate = moment(startDate).startOf('day').toDate();

    this.showEndDate = true;
    this._dataPassingService.passServiceDeploymentEndDate(this.showEndDate);

    const updateServiceDeploymnetFilter = Object.assign(
      this.servicedeployemntfilterParams.serviceDeploymnetTaskLocationSearchParameters,
      {
        serviceStartDate: this.minDate,
      },
    );

    const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
      serviceDeploymnetTaskLocationSearchParameters: updateServiceDeploymnetFilter,
    });

    this.pullData(updateFilter);
  }

  public searchEndDate(endDate: Date) {
    const date = moment(endDate).endOf('day');

    const updateServiceDeploymnetFilter = Object.assign(
      this.servicedeployemntfilterParams.serviceDeploymnetTaskLocationSearchParameters,
      { serviceEndDate: date },
    );
    const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
      serviceDeploymnetTaskLocationSearchParameters: updateServiceDeploymnetFilter,
    });
    this.pullData(updateFilter);
  }

  public searchByNumber(storeNumber: string) {
    storeNumber = storeNumber.trim();

    const updateServiceDeploymnetFilter = Object.assign(
      this.servicedeployemntfilterParams.serviceDeploymnetTaskLocationSearchParameters,
      { numberSearch: storeNumber },
    );
    const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
      serviceDeploymnetTaskLocationSearchParameters: updateServiceDeploymnetFilter,
    });
    this.pullData(updateFilter);
  }

  clearFilter(flag) {
    if (flag) {
      const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
        serviceDeploymnetTaskLocationSearchParameters: new ServicedeploymnetTaskSearchParameters(),
      });

      updateFilter.sortColumn = '-start';
      this.pullData(updateFilter);
    }
  }

  onDownLoad() {
    this.loading = true;
    const subscription = this._serviceDeploymentTaskLocationClient
      .getFile(this.serviceDeploymentRefId, this.taskRefId)
      .subscribe((file: FileResponse) => {
        const date = moment().utc().format('YYYY_MM_DD_HH_mm');
        const fileName = `TaskLocations_${date}_UTC.csv`;
        CommonUtils.download(file.data, fileName);
        this.loading = false;
      });
  }

  public passData(serviceTaskLocationRefNumber: string) {
    const params = Object.assign(
      {},
      { serviceTaskLocationRefNumber: serviceTaskLocationRefNumber },
      { accountId: this.accountId },
      { serviceDeploymentName: this.serviceDeploymentName },
      { serviceTaskName: this.serviceTaskName },
    );
    this._servicedeploymentReduxService.setFilter(params);
  }

  public addServiceDeploymentTaskLocation() {
    this.currentaddedServiceDeploymentTaskLocation = new AddTaskLocation();
    this.currentaddedServiceDeploymentTaskLocation.edit = false;
    this._taskLocationReduxService.setFilter(this.currentaddedServiceDeploymentTaskLocation);
    this._router.navigate([
      '/servicedeployments',
      'addservicedeploymenttasklocation',
      this.serviceDeploymentRefId,
      this.taskRefId,
    ]);
  }

  public editTaskLocation(data: AddTaskLocation) {
    data.edit = true;
    this._taskLocationReduxService.setFilter(data);
    this._router.navigate([
      '/servicedeployments',
      'addservicedeploymenttasklocation',
      this.serviceDeploymentRefId,
      this.taskRefId,
    ]);
  }

  public importTaskLocation() {
    this._router.navigate(['/servicedeployments', 'importtasklocation', this.serviceDeploymentRefId, this.taskRefId]);
  }
}
