<v-loading-container [working]="loading" [spinnerColor]="'#dedede'">
  <div class="container-fluid dashboard-page">
    <header class="page-header" style="text-align: center">
      <h2 class="page title">View Account</h2>
    </header>

    <div [ngClass]="{ 'display-none': loading }" class="row flex-xl-nowrap">
      <div *ngIf="errorMessage" class="text-danger">Unable to Supplier: {{ errorMessage }}</div>

      <div class="col-xl-12">
        <div [ngClass]="checkInviteSupplier()">
          <div class="row">
            <div class="col-md-12" *ngIf="checkstatus === 4">
              <form novalidate [formGroup]="inviteSupplierGroup" (submit)="saveInviteSupplier()">
                <div class="sep-form">
                  <h2>Invited Account</h2>
                  <div class="form-fields">
                    <div class="row">
                      <div class="col-xl-7 col-sm-12">
                        <div class="form-group row">
                          <div class="custom-3">
                            <label>
                              Company Name
                              <span class="required" aria-required="true">*</span>
                            </label>
                          </div>
                          <div class="custom-9">
                            <input
                              class="form-control rounded-0"
                              name="companyName"
                              [(ngModel)]="invitedAccount.invitedCompany"
                              maxlength="50"
                              formControlName="invitedCompany"
                              required
                              [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                            />
                            <div *ngIf="invitedCompanyControl.touched && invitedCompanyControl.errors">
                              <span
                                *ngIf="
                                  invitedCompanyControl.touched &&
                                  (invitedCompanyControl.hasError('required') ||
                                    invitedCompanyControl.hasError('invitedCompany'))
                                "
                                class="text-danger"
                              >
                                A valid Account Name is required.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="custom-3">
                            <label>{{ retailerConfig.retailer }} Supplier Number</label>
                          </div>
                          <div class="custom-9">
                            <input
                              class="form-control rounded-0"
                              name="accountNumber"
                              (blur)="checkInvitedAccountNoAlreadyExist()"
                              (keypress)="isNumberKey($event)"
                              [(ngModel)]="invitedAccount.accountNumber"
                              formControlName="invitedAccountNumber"
                              maxlength="50"
                              [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                            />

                            <div
                              class="spacer"
                              *ngIf="invitedVendorIdMsg && !invitedAccountNumberControl.hasError('required')"
                            >
                              <p-message
                                severity="warn"
                                text="{{ retailerConfig.retailer }} Supplier Number Already Exists"
                              ></p-message>
                            </div>

                            <div
                              class="spacer"
                              *ngIf="vendorIdExistSupplier && !invitedAccountNumberControl.hasError('required')"
                            >
                              <p-message
                                severity="warn"
                                text="Our records show this {{
                                  retailerConfig.retailer
                                }}supplier number is assigned to supplier {{ vendorIdSupplierMsg }}."
                              ></p-message>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="custom-3">
                            <label>
                              Contact Name
                              <span class="required" aria-required="true">*</span>
                            </label>
                          </div>
                          <div class="custom-9">
                            <input
                              class="form-control rounded-0"
                              name="invitedName"
                              [(ngModel)]="invitedAccount.invitedName"
                              maxlength="50"
                              formControlName="invitedName"
                              required
                            />

                            <div *ngIf="invitedNameControl.touched && invitedNameControl.errors">
                              <span
                                *ngIf="
                                  invitedNameControl.touched &&
                                  (invitedNameControl.hasError('required') ||
                                    invitedNameControl.hasError('invitedName'))
                                "
                                class="text-danger"
                              >
                                A valid Contact Name is required.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="custom-3">
                            <label>
                              Contact Email
                              <span class="required" aria-required="true">*</span>
                            </label>
                          </div>
                          <div class="custom-9">
                            <input
                              class="form-control rounded-0"
                              name="invitedEmail"
                              (blur)="checkEmailAlreadyExist()"
                              [(ngModel)]="invitedAccount.invitedEmail"
                              maxlength="50"
                              formControlName="invitedEmail"
                            />

                            <div *ngIf="invitedEmailControl.touched && invitedEmailControl.errors">
                              <span
                                *ngIf="
                                  invitedEmailControl.touched &&
                                  (invitedEmailControl.hasError('required') ||
                                    invitedEmailControl.hasError('invitedEmail'))
                                "
                                class="text-danger"
                              >
                                A valid Contact Email is required.
                              </span>
                            </div>

                            <div
                              class="text-danger"
                              *ngIf="
                                inviteSupplierGroup.controls['invitedEmail'].touched &&
                                !inviteSupplierGroup.controls['invitedEmail'].valid &&
                                !inviteSupplierGroup.controls['invitedEmail'].errors.required
                              "
                            >
                              Not a valid email
                            </div>

                            <div
                              class="text-danger"
                              *ngIf="invitedEmailMsg && !invitedEmailControl.hasError('required')"
                            >
                              Email Already Exist
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="custom-3">
                            <label>
                              Personalized Message
                              <span aria-required="true"></span>
                            </label>
                          </div>
                          <div class="custom-9">
                            <textarea
                              class="form-control rounded-0"
                              [(ngModel)]="invitedAccount.invitedNote"
                              name="invitedNote"
                              formControlName="invitedNotes"
                            ></textarea>
                          </div>
                        </div>

                        <div class="form-group row" *ngIf="showAnote">
                          <div class="custom-3">
                            <label for="empNotes">
                              Company Notes
                              <span class="required" aria-required="true"></span>
                            </label>
                          </div>
                          <div class="custom-9">
                            <textarea
                              class="form-control rounded-0"
                              [(ngModel)]="invitedAccount.aNote"
                              id="empNotes"
                              maxlength="255"
                              formControlName="empNotes"
                              [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                            ></textarea>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="custom-3">
                            <label>Field Group Type</label>
                          </div>
                          <div class="custom-9">
                            <p-dropdown
                              [filter]="true"
                              (onChange)="SetFieldGroupTypeDefaults($event)"
                              [options]="fieldTypeDropModel"
                              formControlName="fieldGroupType"
                              [(ngModel)]="invitedAccount.fieldGroupType"
                              styleClass="w-100"
                              [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                            ></p-dropdown>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="custom-3">
                            <label>Certification</label>
                          </div>
                          <div class="custom-9">
                            <p-dropdown
                              [filter]="true"
                              [options]="certificationDropModel"
                              formControlName="certification"
                              [(ngModel)]="invitedAccount.invitedCourse"
                              styleClass="w-100"
                            ></p-dropdown>
                          </div>
                        </div>

                        <div class="submit-btn" style="text-align: center">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="!inviteSupplierGroup.valid || invitedEmailExist"
                          >
                            Save
                          </button>

                          <button type="button" (click)="redirectToAccountList()" class="btn btn-primary">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div [ngClass]="checkNotInviteSupplier()">
          <form novalidate [formGroup]="registrationGroup" (submit)="approveInvitedAccount()">
            <div class="sep-form">
              <h2>Account Information</h2>
              <div class="form-fields">
                <!-- <div class="note note-info" *ngIf="invitedAccount.aNote != null && invitedAccount.aNote != '' && !enableApproval">{{invitedAccount.aNote}}</div> -->
                <div class="note note-info" *ngIf="invitedAccount.aNote != null && invitedAccount.aNote != ''">
                  {{ invitedAccount.aNote }}
                </div>

                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Company Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          *ngIf="!enableApproval"
                          class="form-control rounded-0"
                          [(ngModel)]="invitedAccount.invitedCompany"
                          maxlength="50"
                          formControlName="companyName"
                          readonly
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        />
                        <input
                          *ngIf="enableApproval"
                          class="form-control rounded-0"
                          [(ngModel)]="invitedAccount.invitedCompany"
                          (blur)="checkAccountNameAlreadyExist()"
                          maxlength="50"
                          formControlName="companyName"
                          required
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        />

                        <div *ngIf="companyNameControl.touched && companyNameControl.errors">
                          <span
                            *ngIf="
                              companyNameControl.touched &&
                              (companyNameControl.hasError('required') || companyNameControl.hasError('companyName'))
                            "
                            class="text-danger"
                          >
                            A valid Company Name is required.
                          </span>
                        </div>

                        <div *ngIf="enableApproval">
                          <div class="spacer" *ngIf="accountNameExist && !companyNameControl.hasError('required')">
                            <p-message
                              severity="warn"
                              text="This company name exists for {{ accountNameMsg }} account."
                            ></p-message>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Abbreviation
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="aabbrev"
                          [(ngModel)]="invitedAccount.aabbrev"
                          maxlength="20"
                          formControlName="aabbrev"
                          required
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        />

                        <div *ngIf="aabbrevControl.touched && aabbrevControl.errors">
                          <span
                            *ngIf="
                              aabbrevControl.touched &&
                              (aabbrevControl.hasError('required') || aabbrevControl.hasError('aabbrev'))
                            "
                            class="text-danger"
                          >
                            A valid Abbreviation is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Supplier Number
                          <span class="required" aria-required="true" *ngIf="checkstatus == 2">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          *ngIf="checkstatus !== 1 && checkstatus !== 2"
                          (blur)="checkVendorExist()"
                          (keypress)="isNumberKey($event)"
                          [(ngModel)]="invitedAccount.accountNumber"
                          maxlength="50"
                          formControlName="accountNumber"
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        />

                        <input
                          class="form-control rounded-0"
                          *ngIf="checkstatus === 1"
                          (keypress)="isNumberKey($event)"
                          [(ngModel)]="invitedAccount.accountNumber"
                          maxlength="50"
                          formControlName="accountNumber"
                          readonly
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        />

                        <input
                          class="form-control rounded-0"
                          *ngIf="checkstatus === 2"
                          (keypress)="isNumberKey($event)"
                          (blur)="checkVendorExist()"
                          [(ngModel)]="invitedAccount.accountNumber"
                          maxlength="50"
                          formControlName="accountNumber"
                          required
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        />

                        <div
                          class="text-danger"
                          *ngIf="vendorIdMsg && !accountNumberControl.hasError('required') && checkstatus === 2"
                        >
                          Supplier Number Already Exists
                        </div>

                        <div
                          class="spacer"
                          *ngIf="
                            vendorIdMsg &&
                            !accountNumberControl.hasError('required') &&
                            checkstatus !== 1 &&
                            checkstatus !== 2
                          "
                        >
                          <p-message severity="warn" text="Supplier Number Already Exists"></p-message>
                        </div>

                        <div class="spacer" *ngIf="vendorIDExist">
                          <p-message
                            severity="warn"
                            text="This supplier number exists for supplier {{ vendorIDExist }}"
                          ></p-message>
                        </div>

                        <div *ngIf="accountNumberControl.touched && accountNumberControl.errors">
                          <span
                            *ngIf="
                              accountNumberControl.touched &&
                              (accountNumberControl.hasError('required') ||
                                accountNumberControl.hasError('accountNumber'))
                            "
                            class="text-danger"
                          >
                            Supplier Number is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Field Group Type
                          <span class="required" aria-required="true" *ngIf="checkstatus != 1">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          (onChange)="SetFieldGroupTypeDefaults($event)"
                          [options]="fieldTypeDropModel"
                          styleClass="w-100"
                          [style]="{ 'margin-left': '15px' }"
                          [formControl]="fieldGroupTypeControl"
                          required
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        ></p-dropdown>

                        <div *ngIf="fieldGroupTypeControl.touched && fieldGroupTypeControl.errors">
                          <span
                            *ngIf="
                              fieldGroupTypeControl.touched &&
                              (fieldGroupTypeControl.hasError('required') ||
                                fieldGroupTypeControl.hasError('fieldGroupType'))
                            "
                            class="text-danger"
                          >
                            FieldGroupType is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="type !== 'AccountId'">
                      <div class="custom-3">
                        <label>
                          Phone Number
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          formControlName="phoneNumber"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="invitedAccount.pPhone"
                          autoClear="true"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="phoneNumberControl.touched && phoneNumberControl.errors">
                          <span
                            *ngIf="
                              phoneNumberControl.touched &&
                              (phoneNumberControl.hasError('required') || phoneNumberControl.hasError('phoneNumber'))
                            "
                            class="text-danger"
                          >
                            A valid Contact Number is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="enableApproval">
                      <div class="form-group row">
                        <div class="custom-3">
                          <label>
                            Admin First Name
                            <span class="required" aria-required="true">*</span>
                          </label>
                        </div>
                        <div class="custom-9">
                          <input
                            class="form-control rounded-0"
                            id="firstName"
                            maxlength="50"
                            [(ngModel)]="invitedAccount.fName"
                            formControlName="firstName"
                            required
                          />

                          <div *ngIf="firstNameControl.touched && firstNameControl.errors">
                            <span
                              *ngIf="
                                firstNameControl.touched &&
                                (firstNameControl.hasError('required') || firstNameControl.hasError('firstName'))
                              "
                              class="text-danger"
                            >
                              A valid First Name is required.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="custom-3">
                          <label>
                            Admin Last Name
                            <span class="required" aria-required="true">*</span>
                          </label>
                        </div>
                        <div class="custom-9">
                          <input
                            class="form-control rounded-0"
                            id="lastName"
                            maxlength="50"
                            [(ngModel)]="invitedAccount.lName"
                            formControlName="lastName"
                            required
                          />

                          <div *ngIf="lastNameControl.touched && lastNameControl.errors">
                            <span
                              *ngIf="
                                lastNameControl.touched &&
                                (lastNameControl.hasError('required') || lastNameControl.hasError('lastName'))
                              "
                              class="text-danger"
                            >
                              A valid Last Name is required.
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="custom-3">
                          <label>
                            Admin Email
                            <span class="required" aria-required="true">*</span>
                          </label>
                        </div>

                        <div class="custom-9">
                          <div class="input-group">
                            <span class="input-group-addon">
                              <i class="fa fa-envelope-o"></i>
                            </span>

                            <input
                              class="form-control rounded-0"
                              id="adminEmail"
                              maxlength="100"
                              (blur)="checkEmailDomainInPerson()"
                              [(ngModel)]="invitedAccount.email1"
                              formControlName="adminEmail"
                              required
                            />
                          </div>
                          <div *ngIf="emailControl.touched && emailControl.errors">
                            <span
                              *ngIf="
                                emailControl.touched &&
                                (emailControl.hasError('required') || emailControl.hasError('adminEmail'))
                              "
                              class="text-danger"
                            >
                              A valid Email is required.
                            </span>
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                              registrationGroup.controls['adminEmail'].touched &&
                              !registrationGroup.controls['adminEmail'].valid &&
                              !registrationGroup.controls['adminEmail'].errors.required
                            "
                          >
                            Not a valid email
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="custom-12">
                          <div
                            class="spacer"
                            *ngIf="emailMsg && !emailControl.hasError('required')"
                            style="text-align: center"
                          >
                            <p-message
                              severity="warn"
                              text="This domain exists for {{ emailExist }} accounts."
                            ></p-message>
                          </div>
                        </div>
                        <div class="custom-3"></div>
                        <div class="custom-5">
                          <div
                            class="text-success"
                            style="text-align: center"
                            *ngIf="emailMsg && !emailControl.hasError('required')"
                          >
                            <span style="text-align: center">
                              <span>Please</span>
                              <a (click)="UpdateInvitedAnote()" style="color: #007bff">click here</a>
                              <span>to add notes</span>
                            </span>
                          </div>

                          <div
                            class="text-success"
                            style="text-align: center"
                            *ngIf="emailMsg && !emailControl.hasError('required') && !showANoteClick"
                          >
                            <span style="color: #007bff">Note:-</span>
                            <a (click)="UpdateInvitedAnote()" style="color: #007bff; text-decoration: underline">
                              {{ invitedAccount.aNote }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row"></div>

                    <div class="form-group row" *ngIf="type == 'AccountId'">
                      <div class="custom-3">
                        <label>
                          Primary Admin
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          [options]="primaryAdminDropModel"
                          [(ngModel)]="invitedAccount.primaryAdminId"
                          [style]="{ width: '300px', 'margin-left': '15px' }"
                          formControlName="primaryAdmin"
                        ></p-dropdown>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="type == 'AccountId' || checkstatus == 3">
                      <div class="custom-3">
                        <label>
                          Status
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          [options]="statusDropDownModel"
                          (onChange)="statusChange($event)"
                          [ngModel]="invitedAccount?.statusId?.toString()"
                          name="status"
                          [style]="{ width: '300px', 'margin-left': '15px' }"
                          formControlName="status"
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        ></p-dropdown>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Is Seasonal
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                                class="ddlcss"
                                [options]="yesNoDropDownModel"
                                [(ngModel)]="invitedAccount.isSeasonal"
                                [style]="{ width: '300px', 'margin-left': '15px' }"
                                [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                                formControlName="isSeasonal"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="showAnote">
                      <div class="custom-3">
                        <label for="empNotes">
                          Company Notes
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <textarea
                          class="form-control rounded-0"
                          [(ngModel)]="invitedAccount.aNote"
                          maxlength="255"
                          formControlName="empNotes"
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="custom-5">
                    <div class="form-group row">
                      <div class="custom-6">
                        <label>
                          Company Logo
                          <span class="required" aria-required="true">*</span>
                        </label>
                        <span class="image-text">
                          Upload an image file with the extension of
                          <br />
                          JPG or PNG.
                          <br />
                          <br />
                          Your company logo will be displayed on the rep ID badges.
                          <br />
                          <br />
                          The image size limit is
                          <br />
                          8MB.
                        </span>
                      </div>
                      <div class="custom-6">
                        <div class="img-upload">
                          <div *ngIf="!files.length; else hasFiles">
                            <img
                              *ngIf="invitedAccount.aimageUrl | imageFlow: 'h-300' | async as url; else noImage"
                              [src]="url"
                              (click)="onClickImage(url)"
                            />
                            <ng-template #noImage>
                              <img src="../../../assets/img/noimage.gif" />
                            </ng-template>
                          </div>
                          <ng-template #hasFiles>
                            <div *ngFor="let file of files" class="mt-1">
                              <img *ngIf="file.imgSrc" [src]="file.imgSrc" (click)="onClickImage(file.imgSrc)" />
                            </div>
                          </ng-template>
                        </div>
                        <input
                          type="file"
                          id="files"
                          [size]="8000000"
                          [withMeta]="true"
                          class="form-control-file"
                          name="file"
                          [allowedExt]="'(.png|.jpg|.jpeg)'"
                          formControlName="files"
                        />

                        <div *ngIf="fileCtrl.invalid && fileCtrl.dirty">
                          <div class="text-danger" *ngIf="fileCtrl.hasError('fileType')">Invalid File Type</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ng-container
              *permissions="[
                pc.names.AccountsManageHierarchy,
                pc.privilege.Read,
                showAccountHierarchyComponent$ | async
              ]"
            >
              <div class="sep-form">
                <h2>Account Hierarchy</h2>
                <div class="form-fields">
                  <account-hierarchy [selectedAccountId]="accountId" [pc]="pc"></account-hierarchy>
                </div>
              </div>
            </ng-container>

            <div class="sep-form">
              <h2>Billing contact</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Billing Contact Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="billingContactName"
                          maxlength="50"
                          [(ngModel)]="invitedAccount.billAttnToFname"
                          formControlName="billingContactName"
                          required
                        />

                        <div *ngIf="biilingContactNameControl.touched && biilingContactNameControl.errors">
                          <span
                            *ngIf="
                              biilingContactNameControl.touched &&
                              (biilingContactNameControl.hasError('required') ||
                                biilingContactNameControl.hasError('billingContactName'))
                            "
                            class="text-danger"
                          >
                            A valid Billing Contact Name is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Billing Email
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-envelope-o"></i>
                          </span>

                          <input
                            class="form-control rounded-0"
                            maxlength="100"
                            id="billingEmail"
                            [(ngModel)]="invitedAccount.billingEmail"
                            formControlName="billingEmail"
                            required
                          />
                          <div *ngIf="billingEmailControl.touched && billingEmailControl.errors">
                            <span
                              *ngIf="
                                billingEmailControl.touched &&
                                (billingEmailControl.hasError('required') ||
                                  billingEmailControl.hasError('billingEmail'))
                              "
                              class="text-danger"
                            >
                              A valid Billing Email is required.
                            </span>
                          </div>
                          <div
                            class="text-danger"
                            *ngIf="
                              registrationGroup.controls['billingEmail'].touched &&
                              !registrationGroup.controls['billingEmail'].valid &&
                              !registrationGroup.controls['billingEmail'].errors.required
                            "
                          >
                            Not a valid email
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Billing Phone
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          formControlName="billingPhone"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="invitedAccount.bphone"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="billingContactControl.touched && billingContactControl.errors">
                          <span
                            *ngIf="
                              billingContactControl.touched &&
                              (billingContactControl.hasError('required') ||
                                billingContactControl.hasError('billingPhone'))
                            "
                            class="text-danger"
                          >
                            A valid Billing Phone Number is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Street Address Line 1
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="billingStreetAddress"
                          [(ngModel)]="invitedAccount.baddress1"
                          formControlName="billingStreetAddress"
                          required
                        />

                        <div *ngIf="biilingStreetAddressControl.touched && biilingStreetAddressControl.errors">
                          <span
                            *ngIf="
                              biilingStreetAddressControl.touched &&
                              (biilingStreetAddressControl.hasError('required') ||
                                biilingStreetAddressControl.hasError('billingStreetAddress'))
                            "
                            class="text-danger"
                          >
                            A valid Street Address Line 1 is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Street Address Line 2
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          id="billingStreetAddress2"
                          [(ngModel)]="invitedAccount.baddress2"
                          formControlName="billingStreetAddress2"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          City
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="billingCity"
                          [(ngModel)]="invitedAccount.bcity"
                          formControlName="billingCity"
                          required
                        />

                        <div *ngIf="billingCityControl.touched && billingCityControl.errors">
                          <span
                            *ngIf="
                              billingCityControl.touched &&
                              (billingCityControl.hasError('required') || billingCityControl.hasError('billingCity'))
                            "
                            class="text-danger"
                          >
                            A valid City is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          State
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          [options]="stateDropDownModel"
                          [(ngModel)]="invitedAccount.bstate"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="billingState"
                        ></p-dropdown>

                        <div *ngIf="billingStateControl.touched && billingStateControl.errors">
                          <span
                            *ngIf="
                              billingStateControl.touched &&
                              (billingStateControl.hasError('required') || billingStateControl.hasError('billingState'))
                            "
                            class="text-danger"
                          >
                            A valid State is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Country
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          [options]="countryDropDownModel"
                          (onChange)="resetZipBillingCodeMsg($event)"
                          [(ngModel)]="invitedAccount.bcountry"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="billingCountry"
                        ></p-dropdown>

                        <div *ngIf="billingCountryControl.touched && billingCountryControl.errors">
                          <span
                            *ngIf="
                              billingCountryControl.touched &&
                              (billingCountryControl.hasError('required') ||
                                billingCountryControl.hasError('billingCountry'))
                            "
                            class="text-danger"
                          >
                            A valid Country is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="invitedAccount.bcountry == 'USA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="99999?-9999"
                          formControlName="billingZipCode"
                          slotChar=" "
                          (onBlur)="onBlurBillingZipCode()"
                          [(ngModel)]="invitedAccount.bzip"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="5"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="billingZipCodeControl.touched && billingZipCodeControl.errors">
                          <span
                            *ngIf="
                              !zipBillingCodeMsg &&
                              billingZipCodeControl.touched &&
                              (billingZipCodeControl.hasError('required') ||
                                billingZipCodeControl.hasError('billingZipCode'))
                            "
                            class="text-danger"
                          >
                            A valid Zip Code is required.
                          </span>

                          <span *ngIf="zipBillingCodeMsg" class="text-danger">
                            Zip Code must be at least 5 characters
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="invitedAccount.bcountry == 'CANADA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="***-***"
                          formControlName="billingZipCode"
                          slotChar=" "
                          (onBlur)="onBlurBillingZipCode()"
                          [(ngModel)]="invitedAccount.bzip"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="6"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="billingZipCodeControl.touched && billingZipCodeControl.errors">
                          <span
                            *ngIf="
                              !zipBillingCanMsg &&
                              billingZipCodeControl.touched &&
                              (billingZipCodeControl.hasError('required') ||
                                billingZipCodeControl.hasError('billingZipCode'))
                            "
                            class="text-danger"
                          >
                            A valid Zip Code is required.
                          </span>

                          <span *ngIf="zipBillingCanMsg" class="text-danger">
                            Zip Code must be at least 6 characters
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sep-form">
              <h2>Shipping contact</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Badge Shipping
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          [options]="badgeShippingDropDownList"
                          [style]="{ 'margin-left': '15px' }"
                          formControlName="badgeShipping"
                          [disableControlPermission]="[pc.names.AccountsManageBadgeShipping, pc.privilege.Update]"
                        ></p-dropdown>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Print Badge
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          name="printBadge"
                          [filter]="true"
                          [options]="printBadgeDropDownModel"
                          [(ngModel)]="invitedAccount.printBadge"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="printBadge"
                          [disableControlPermission]="[pc.names.AccountsManageMoreDetails, pc.privilege.Update]"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Shipping Contact Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="shippingContactName"
                          [(ngModel)]="invitedAccount.shipAttnTo"
                          formControlName="shippingContactName"
                          required
                        />

                        <div *ngIf="shippingContactNameControl.touched && shippingContactNameControl.errors">
                          <span
                            *ngIf="
                              shippingContactNameControl.touched &&
                              (shippingContactNameControl.hasError('required') ||
                                shippingContactNameControl.hasError('shippingContactName'))
                            "
                            class="text-danger"
                          >
                            A valid Shipping Contact Name is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Street Address Line 1
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="shippingStreetAddress"
                          [(ngModel)]="invitedAccount.saddress1"
                          formControlName="shippingStreetAddress"
                          required
                        />

                        <div *ngIf="shippingStreetAddressControl.touched && shippingStreetAddressControl.errors">
                          <span
                            *ngIf="
                              shippingStreetAddressControl.touched &&
                              (shippingStreetAddressControl.hasError('required') ||
                                shippingStreetAddressControl.hasError('shippingStreetAddress'))
                            "
                            class="text-danger"
                          >
                            A valid Street Address Line 1 is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Street Address Line 2
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          class="form-control rounded-0"
                          maxlength="50"
                          id="shippingStreetAddress2"
                          [(ngModel)]="invitedAccount.saddress2"
                          formControlName="shippingStreetAddress2"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          City
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="shippingCity"
                          maxlength="50"
                          [(ngModel)]="invitedAccount.scity"
                          formControlName="shippingCity"
                          required
                        />

                        <div *ngIf="shippingCityControl.touched && shippingCityControl.errors">
                          <span
                            *ngIf="
                              shippingCityControl.touched &&
                              (shippingCityControl.hasError('required') || shippingCityControl.hasError('shippingCity'))
                            "
                            class="text-danger"
                          >
                            A valid City is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          State
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          [options]="stateDropDownModel"
                          [(ngModel)]="invitedAccount.sstate"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="shippingState"
                        ></p-dropdown>

                        <div *ngIf="shippingStateControl.touched && shippingStateControl.errors">
                          <span
                            *ngIf="
                              shippingStateControl.touched &&
                              (shippingStateControl.hasError('required') ||
                                shippingStateControl.hasError('shippingState'))
                            "
                            class="text-danger"
                          >
                            A valid State is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Country
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          [options]="countryDropDownModel"
                          (onChange)="resetZipShippingCodeMsg($event)"
                          [(ngModel)]="invitedAccount.scountry"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="shippingCountry"
                        ></p-dropdown>

                        <div *ngIf="shippingCountryControl.touched && shippingCountryControl.errors">
                          <span
                            *ngIf="
                              shippingCountryControl.touched &&
                              (shippingCountryControl.hasError('required') ||
                                shippingCountryControl.hasError('shippingCountry'))
                            "
                            class="text-danger"
                          >
                            A valid Country is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="invitedAccount.scountry == 'USA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="99999?-9999"
                          formControlName="shippingZipCode"
                          slotChar=" "
                          (onBlur)="onBlurShippingZipCode()"
                          [(ngModel)]="invitedAccount.szip"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="5"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="shippingZipCodeControl.touched && shippingZipCodeControl.errors">
                          <span
                            *ngIf="
                              !zipShippingCodeMsg &&
                              shippingZipCodeControl.touched &&
                              (shippingZipCodeControl.hasError('required') ||
                                shippingZipCodeControl.hasError('shippingZipCode'))
                            "
                            class="text-danger"
                          >
                            A valid Zip Code is required.
                          </span>

                          <span *ngIf="zipShippingCodeMsg" class="text-danger">
                            Zip Code must be at least 5 characters
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="invitedAccount.scountry == 'CANADA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <p-inputMask
                          mask="***-***"
                          formControlName="shippingZipCode"
                          slotChar=" "
                          (onBlur)="onBlurShippingZipCode()"
                          [(ngModel)]="invitedAccount.szip"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="6"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="shippingZipCodeControl.touched && shippingZipCodeControl.errors">
                          <span
                            *ngIf="
                              !zipShippingCanMsg &&
                              shippingZipCodeControl.touched &&
                              (shippingZipCodeControl.hasError('required') ||
                                shippingZipCodeControl.hasError('shippingZipCode'))
                            "
                            class="text-danger"
                          >
                            A valid Zip Code is required.
                          </span>

                          <span *ngIf="zipShippingCanMsg" class="text-danger">
                            Zip Code must be at least 6 characters
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sep-form">
              <h2>Technical Contact</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Support Contact Email
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-envelope-o"></i>
                          </span>

                          <input
                            class="form-control rounded-0"
                            maxlength="100"
                            [(ngModel)]="invitedAccount.supportEmail"
                            id="supportContactEmail"
                            formControlName="supportContactEmail"
                            required
                          />
                        </div>
                        <span style="font-size: 10px">
                          This is the email address your Field Reps will use to contact your support team. This is shown
                          to them on the support page of the app.
                        </span>

                        <div *ngIf="supportContactEmailControl.touched && supportContactEmailControl.errors">
                          <span
                            *ngIf="
                              supportContactEmailControl.touched &&
                              (supportContactEmailControl.hasError('required') ||
                                supportContactEmailControl.hasError('supportContactEmail'))
                            "
                            class="text-danger"
                          >
                            A valid Support Contact Email is required.
                          </span>
                        </div>
                        <div
                          class="text-danger"
                          *ngIf="
                            registrationGroup.controls['supportContactEmail'].touched &&
                            !registrationGroup.controls['supportContactEmail'].valid &&
                            !registrationGroup.controls['supportContactEmail'].errors.required
                          "
                        >
                          Not a valid email
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Support Contact Phone
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          formControlName="supportPhone"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="invitedAccount.supportPhone"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          required="true"
                        ></p-inputMask>

                        <span style="font-size: 10px">
                          This is the Phone Number Field Reps will use to contact your support team. This is shown to
                          them on the support page of the app.
                        </span>

                        <div *ngIf="supportContactNumberControl.touched && supportContactNumberControl.errors">
                          <span
                            *ngIf="
                              supportContactNumberControl.touched &&
                              (supportContactNumberControl.hasError('required') ||
                                supportContactNumberControl.hasError('supportPhone'))
                            "
                            class="text-danger"
                          >
                            A valid Support Contact Phone Number is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Support Contact Note
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <textarea
                          class="form-control rounded-0"
                          maxlength="255"
                          [(ngModel)]="invitedAccount.supportNote"
                          id="supportContactNote"
                          formControlName="supportContactNote"
                        ></textarea>
                        <span style="font-size: 10px">
                          This is shown to the Field Rep on the support page of the app. This note is often used to tell
                          your users to call their manager before the support line and/or to show the support hours.
                        </span>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="roleEnabled">
                      <div class="custom-3">
                        <label>
                          Store Support Information
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [filter]="true"
                          [options]="showStoreSupportDropDownModel"
                          [(ngModel)]="invitedAccount.showStoreSupportInfo"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="showStoreSupportInfo"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="roleEnabled">
                      <div class="custom-3">
                        <label>
                          Store Support number
                          <span aria-required="true"></span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <p-inputMask
                          formControlName="storeSupportNumber"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="invitedAccount.storeSupportNumber"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                        ></p-inputMask>

                        <div *ngIf="storeSupportNumberControl.touched && storeSupportNumberControl.errors">
                          <span
                            *ngIf="
                              storeSupportNumberControl.touched &&
                              (storeSupportNumberControl.hasError('required') ||
                                storeSupportNumberControl.hasError('storeSupportNumber'))
                            "
                            class="text-danger"
                          >
                            A valid Store Support number Number is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="roleEnabled">
                      <div class="custom-3">
                        <label>
                          Store Support Email
                          <span ria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-envelope-o"></i>
                          </span>

                          <input
                            class="form-control rounded-0"
                            maxlength="100"
                            [(ngModel)]="invitedAccount.storeSupportEmail"
                            id="storeSupportEmail"
                            formControlName="storeSupportEmail"
                          />
                        </div>

                        <div
                          class="text-danger"
                          *ngIf="
                            registrationGroup.controls['storeSupportEmail'].touched &&
                            !registrationGroup.controls['storeSupportEmail'].valid &&
                            !registrationGroup.controls['storeSupportEmail'].errors.required
                          "
                        >
                          Not a valid email
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Technical Contact Email
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-envelope-o"></i>
                          </span>

                          <input
                            class="form-control rounded-0"
                            maxlength="100"
                            [(ngModel)]="invitedAccount.itcontactEmail"
                            id="technicalContactEmail"
                            formControlName="technicalContactEmail"
                            required
                          />
                        </div>
                        <span style="font-size: 10px">
                          This is the email address of the technical lead for this program.
                        </span>

                        <div *ngIf="technicalContactEmailControl.touched && technicalContactEmailControl.errors">
                          <span
                            *ngIf="
                              technicalContactEmailControl.touched &&
                              (technicalContactEmailControl.hasError('required') ||
                                technicalContactEmailControl.hasError('technicalContactEmail'))
                            "
                            class="text-danger"
                          >
                            A valid Technical Contact Email is required.
                          </span>
                        </div>
                        <div
                          class="text-danger"
                          *ngIf="
                            registrationGroup.controls['technicalContactEmail'].touched &&
                            !registrationGroup.controls['technicalContactEmail'].valid &&
                            !registrationGroup.controls['technicalContactEmail'].errors.required
                          "
                        >
                          Not a valid email
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Technical Contact Phone
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <p-inputMask
                          formControlName="technicalPhone"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="invitedAccount.itcontactPhone"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          required="true"
                        ></p-inputMask>

                        <span style="font-size: 10px">
                          This is the phone number of the technical lead for this program.
                        </span>

                        <div *ngIf="technicalContactNumberControl.touched && technicalContactNumberControl.errors">
                          <span
                            *ngIf="
                              technicalContactNumberControl.touched &&
                              (technicalContactNumberControl.hasError('required') ||
                                technicalContactNumberControl.hasError('technicalPhone'))
                            "
                            class="text-danger"
                          >
                            A valid Technical Contact Phone Number is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="checkstatus == 1">
                      <div class="custom-3">
                        <label>api_key</label>
                      </div>
                      <div class="custom-9">
                        <div class="input-group">
                          <input
                            class="form-control rounded-0"
                            [(ngModel)]="invitedAccount.authToken"
                            id="authToken"
                            formControlName="authToken"
                            readonly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sep-form">
              <h2>Additional Details</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Do you have user-specific email
                          <br />
                          addresses for your reps?
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [options]="yesNoDropDownModel"
                          [(ngModel)]="invitedAccount.userSpecificEmailsForCommunication"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="userSpecificEmails"
                        ></p-dropdown>
                        <br />
                        <span style="font-size: 10px">Volt sends password reset emails directly to users</span>

                        <div
                          *ngIf="
                            userSpecificEmailForCommunicationControl.touched &&
                            userSpecificEmailForCommunicationControl.errors
                          "
                        >
                          <span
                            *ngIf="
                              userSpecificEmailForCommunicationControl.touched &&
                              (userSpecificEmailForCommunicationControl.hasError('required') ||
                                userSpecificEmailForCommunicationControl.hasError('estimatedUser'))
                            "
                            class="text-danger"
                          >
                            This field is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Do you provide your employees
                          <br />
                          with a badge that contains
                          <br />
                          a picture of the user?
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [options]="yesNoDropDownModel"
                          [(ngModel)]="invitedAccount.provideBadgeWithPhoto"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="provideBadgeWithPhoto"
                        ></p-dropdown>
                        <div *ngIf="provideBadgeWithPhotoControl.touched && provideBadgeWithPhotoControl.errors">
                          <span
                            *ngIf="
                              provideBadgeWithPhotoControl.touched &&
                              (provideBadgeWithPhotoControl.hasError('required') ||
                                provideBadgeWithPhotoControl.hasError('estimatedUser'))
                            "
                            class="text-danger"
                          >
                            This field is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Estimated # Users
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="100"
                          class="form-control rounded-0"
                          [(ngModel)]="invitedAccount.estimatedUsers"
                          id="estimatedUser"
                          formControlName="estimatedUser"
                          required
                        />
                        <div *ngIf="estimatedUserControl.touched && estimatedUserControl.errors">
                          <span
                            *ngIf="
                              estimatedUserControl.touched &&
                              (estimatedUserControl.hasError('required') ||
                                estimatedUserControl.hasError('estimatedUser'))
                            "
                            class="text-danger"
                          >
                            Estimated # Users is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Are Users Directly Employed?
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          [(ngModel)]="invitedAccount.uDirectlyEmployed"
                          class="form-control rounded-0"
                          id="userEmployed"
                          formControlName="userEmployed"
                          required
                        />
                        <div *ngIf="userEmployedControl.touched && userEmployedControl.errors">
                          <span
                            *ngIf="
                              userEmployedControl.touched &&
                              (userEmployedControl.hasError('required') || userEmployedControl.hasError('userEmployed'))
                            "
                            class="text-danger"
                          >
                            Are Users Directly Employed? is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Are Users Mobile Enabled?
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          [(ngModel)]="invitedAccount.uMobileEnabled"
                          class="form-control rounded-0"
                          id="userMobileEnabled"
                          formControlName="userMobileEnabled"
                          required
                        />
                        <div *ngIf="userMobileEnabledControl.touched && userMobileEnabledControl.errors">
                          <span
                            *ngIf="
                              userMobileEnabledControl.touched &&
                              (userMobileEnabledControl.hasError('required') ||
                                userMobileEnabledControl.hasError('userMobileEnabled'))
                            "
                            class="text-danger"
                          >
                            Are Users Mobile Enabled? is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div [ngClass]="checkCertification()">
                      <div class="form-group row">
                        <div class="custom-3">
                          <label>
                            Certification
                            <span class="required" aria-required="true" *ngIf="checkstatus != 1">*</span>
                          </label>
                        </div>
                        <div class="custom-9">
                          <p-dropdown
                            class="ddlcss"
                            [options]="certificationDropModel"
                            *ngIf="checkstatus != 1"
                            [style]="{ width: '300px', 'margin-left': '15px' }"
                            [formControl]="certificationControl"
                            [filter]="true"
                            required
                          ></p-dropdown>
                          <div *ngIf="certificationControl.touched && certificationControl.errors">
                            <span
                              *ngIf="
                                certificationControl.touched &&
                                (certificationControl.hasError('required') ||
                                  certificationControl.hasError('certification'))
                              "
                              class="text-danger"
                            >
                              Certification is required.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Corporate Website
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          [(ngModel)]="invitedAccount.awebsite"
                          id="userCorporateWebsite"
                          formControlName="userCorporateWebsite"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Corporate Address 1
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          [(ngModel)]="invitedAccount.aaddress1"
                          id="userAddressLine1"
                          formControlName="userAddressLine1"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Corporate Address 2
                          <span aria-required="true"></span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          [(ngModel)]="invitedAccount.aaddress2"
                          id="userAddressLine2"
                          formControlName="userAddressLine2"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          City
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          [(ngModel)]="invitedAccount.acity"
                          id="userCity"
                          formControlName="userCity"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          State
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [options]="stateDropDownModel"
                          [(ngModel)]="invitedAccount.astate"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="userState"
                          [filter]="true"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Country
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          class="ddlcss"
                          [options]="countryDropDownModel"
                          (onChange)="resetZipUserCodeMsg($event)"
                          [(ngModel)]="invitedAccount.acountry"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="userCountry"
                          [filter]="true"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="invitedAccount.acountry == 'USA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="99999?-9999"
                          formControlName="userZipCode"
                          slotChar=" "
                          (onBlur)="onBlurUserZipCode()"
                          [(ngModel)]="invitedAccount.azip"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="5"
                        ></p-inputMask>

                        <div *ngIf="userZipCodeControl.touched && userZipCodeControl.errors">
                          <span *ngIf="zipUserCodeMsg" class="text-danger">Zip Code must be at least 5 characters</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="invitedAccount.acountry == 'CANADA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="***-***"
                          formControlName="userZipCode"
                          slotChar=" "
                          (onBlur)="onBlurUserZipCode()"
                          [(ngModel)]="invitedAccount.azip"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="5"
                        ></p-inputMask>

                        <div *ngIf="userZipCodeControl.touched && userZipCodeControl.errors">
                          <span *ngIf="zipUserCanMsg" class="text-danger">Zip Code must be at least 6 characters</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              [ngClass]="checkShowHideValidation()"
              *permissions="[pc.names.AccountsManageMoreDetails, pc.privilege.Read]"
            >
              <div class="sep-form">
                <h2>Certification List</h2>

                <div class="form-fields">
                  <div class="row">
                    <div class="form-group row table-fit">
                      <certification-list
                        #certificatonList
                        [certificationselected]="tempSelectedCertification"
                        (certificationData)="getCertificationData($event)"
                      ></certification-list>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sep-form" *permissions="[pc.names.AccountsManageMoreDetails, pc.privilege.Read]">
                <h2>Product List</h2>

                <div class="form-fields">
                  <div class="row">
                    <div class="form-group row table-fit">
                      <product-list
                        #productData
                        [productselected]="tempSelectedProduct"
                        (productData)="getProductData($event)"
                      ></product-list>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sep-form" *permissions="[pc.names.AccountsManageMoreDetails, pc.privilege.Read]">
                <h2>App List</h2>
                <div class="form-fields">
                  <div class="row">
                    <div class="form-group row table-fit">
                      <app-list #appList [appSelected]="tempselectedApp" (appData)="getAppListData($event)"></app-list>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="translatedData$ | async as translatedData">
                <div class="sep-form" *permissions="[pc.names.AccountsManageMoreDetails, pc.privilege.Read]">
                  <h2>{{ translatedData.reservedTagsForUsers }}</h2>
                  <div class="form-fields">
                    <div class="row">
                      <div class="form-group row table-fit">
                        <account-tags
                          #accountTagList
                          [translatedData]="translatedData"
                          (tagData)="getTagListData($event)"
                          [useAccount]="type == 'AccountId'"
                        ></account-tags>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="sep-form" *permissions="[pc.names.AccountsManageMoreDetails, pc.privilege.Read]">
                <h2>Retailer List</h2>
                <div class="form-fields">
                  <div class="row">
                    <div class="form-group row table-fit">
                      <retailer-list
                        #retailerList
                        [retailerSelected]="tempSelectedRetailer"
                        (retailerData)="getRetailerData($event)"
                      ></retailer-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div [ngClass]="checkDeepLink()" *permissions="[pc.names.AccountsManageMoreDetails, pc.privilege.Read]">
              <div class="sep-form">
                <h2>Non-Field Roles</h2>
                <div class="form-fields">
                  <div class="row">
                    <div class="form-group row table-fit">
                      <non-field-roles-list #nonFieldRolesList></non-field-roles-list>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sep-form" *permissions="[pc.names.AccountsManageMoreDetails, pc.privilege.Read]">
                <h2>
                  Application Links (Deep Links)

                  <button
                    pButton
                    (click)="accountAppLinkModal()"
                    style="float: right; border: 0px"
                    class="add-btn"
                    type="button"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </h2>

                <div class="form-fields">
                  <div class="row">
                    <div class="form-group row">
                      <p-message
                        severity="warn"
                        text="Adding/Changing these details affects the links that all of the Field Reps for the account will see. You must press the SAVE button at the bottom of the page for these changes to take effect."
                      ></p-message>
                    </div>

                    <div class="form-group row table-fit">
                      <applink-list #appLinkData (appLinkData)="getAppLinkMsg($event)"></applink-list>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ng-container
              *permissions="[pc.names.AccountsManage, pc.privilege.Read, showAccountHierarchyComponent$ | async]"
            >
              <div class="sep-form">
                <h2>Alternate Supplier Numbers</h2>

                <div class="form-fields">
                  <div class="alternate-supplier-numbers-content">
                    <account-supplier-numbers
                      [accountId]="accountId"
                      [primaryNumber]="invitedAccount.accountNumber"
                    ></account-supplier-numbers>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="submit-btn" style="text-align: center" *ngIf="enableApproval">
              <button type="submit" *ngIf="type !== 'AccountId' && checkstatus === 2" class="btn btn-success">
                Approve
              </button>

              &nbsp;
              <button type="button" (click)="rejectInvitedAccount()" class="btn btn-danger">Reject</button>
            </div>

            <div class="submit-btn" style="text-align: center">
              <div *ngIf="roleEnabled && enableApproval != true">
                <button *ngIf="type === 'AccountId'" type="submit" class="btn btn-primary">Save</button>

                <button type="submit" *ngIf="type !== 'AccountId' && checkstatus !== 2" class="btn btn-primary">
                  Save
                </button>
                <button type="button" (click)="redirectToAccountList()" class="btn btn-primary">Cancel</button>
              </div>

              <div *ngIf="!roleEnabled && !enableApproval">
                <button type="button" (click)="redirectToAccountList()" class="btn btn-primary">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</v-loading-container>
