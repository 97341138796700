<v-container-wrapper [showHeader]="false">
  <ng-template vContainerTemplate="content" let-translated="translated">
    <header class="page-header d-flex align-items-center justify-content-between">
      <h2 class="page-title">
        <span data-cy="ser-dep-page-title" class="me-2">Service Deployments</span>
        <v-refresh-button (refreshClick)="refresh()" data-cy="ser-dep-refresh-button"></v-refresh-button>
      </h2>
    </header>

    <service-deployment-filter
      [filters]="filters"
      [translations]="translated"
      [unfilteredList]="cols"
      [canCreateDeployments]="canCreateDeployment"
      (filterChanged)="onFiltersChanged($event)"
      (export)="onDownload()"
      (createDeployment)="addServiceDeployments()"
    ></service-deployment-filter>

    <v-loading-container [working]="loading" [spinnerColor]="'#dedede'">
      <div class="row mt-3">
        <div class="col-12">
          <p-table
            #dt
            [columns]="cols"
            [value]="data"
            [rows]="20"
            [totalRecords]="totalRecords"
            [responsive]="true"
            [lazy]="true"
            [scrollable]="true"
            scrollDirection="both"
            (onLazyLoad)="sortdata($event)"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th
                  [style]="{ width: '200px' }"
                  *ngFor="let col of columns"
                  [pSortableColumn]="
                    col.field != 'serviceDeploymentTaskCount' &&
                    col.field != 'aname' &&
                    col.field != 'note' &&
                    col.field != 'visitOverview'
                      ? col.field
                      : undefined
                  "
                  [attr.data-cy]="'ser-dep-table-' + col.header + '-label'"
                >
                  {{ col.header }}
                  <ng-container
                    *ngIf="
                      col.field != 'serviceDeploymentTaskCount' &&
                      col.field != 'aname' &&
                      col.field != 'note' &&
                      col.field != 'visitOverview'
                    "
                  >
                    <p-sortIcon class="p-sortable-column-icon" [field]="col.field"></p-sortIcon>
                  </ng-container>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td
                  *ngFor="let col of columns"
                  [attr.data-cy]="'ser-dep-table-' + col.header"
                  [style]="{ width: '200px' }"
                >
                  <span class="p-column-title">{{ col.header }}</span>
                  <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'referenceNumber'">
                      <a
                        (click)="editServiceDeployment(rowData)"
                        style="color: cornflowerblue; text-decoration: underline"
                      >
                        {{ rowData[col.field] }}
                      </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'serviceDeploymentTaskCount'">
                      <button
                        type="button"
                        [ngClass]="rowData[col.field] == '0' ? 'btn btn-danger' : 'btn btn-success'"
                        [routerLink]="['/servicedeployments', rowData['id']]"
                        (click)="passData(rowData)"
                        [style]="{ width: '80px' }"
                      >
                        {{ rowData[col.field] }}
                      </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'serviceStart'">
                      <span>{{ rowData[col.field] | date: 'short' || 'N/A' }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'serviceEnd'">
                      <span>{{ rowData[col.field] | date: 'short' || 'N/A' }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'createdDate'">
                      <span>{{ rowData[col.field] | date: 'short':'UTC' || 'N/A' }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'updatedDate'">
                      <span>{{ rowData[col.field] | date: 'short':'UTC' || 'N/A' }}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'note'">
                      <long-text-limit
                        [text]="rowData[col.field]"
                        [title]="rowData['name'] + ' - Note'"
                      ></long-text-limit>
                    </ng-container>
                    <ng-container *ngSwitchCase="'visitOverview'">
                      <long-text-limit
                        [text]="rowData[col.field]"
                        [title]="rowData['name'] + ' - Visit Overview'"
                      ></long-text-limit>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ rowData[col.field] }}
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="col-md-12">
          <ngb-pagination
            [collectionSize]="totalRecords"
            class="d-flex justify-content-center"
            [pageSize]="servicedeployemntfilterParams.pageSize"
            [page]="servicedeployemntfilterParams.page"
            [maxSize]="5"
            [rotate]="true"
            [ellipses]="true"
            (pageChange)="pagination($event)"
            data-cy="ser-dep-pagination"
          ></ngb-pagination>
        </div>
      </div>
    </v-loading-container>
  </ng-template>
</v-container-wrapper>
