<div class="container-fluid" *ngIf="{ hasRetailtainmentActivity: hasRetailtainmentActivity$ | async } as vm">
  <header class="page-header d-flex align-items-center">
    <h2 class="page-title">
      <span>Tasks</span>
    </h2>
    <div class="page-title-bar">
      <a class="" [routerLink]="['/servicedeployments/data']">
        <i class="fa fa-chevron-left"></i>
        Back to Service Deployments
      </a>
    </div>
    <div class="ms-auto">
      <button class="btn btn-primary btn-sm" type="button" (click)="onDownLoad()">
        <i class="fa fa-download"></i>
        Export
      </button>
      <button
        class="btn btn-primary btn-sm ms-2"
        type="button"
        (click)="addServiceDeploymentTasks()"
        *ngIf="canAdd"
        data-cy="ser-dep-task-add-button"
      >
        <i class="fa fa-file"></i>
        Add Service Task
      </button>
      <button
        class="btn btn-primary btn-sm ms-2"
        *ngIf="canAdd && vm.hasRetailtainmentActivity"
        type="button"
        (click)="addRetailtainmentEvent()"
      >
        <i class="fa fa-file"></i>
        Add Retailtainment Event
      </button>
    </div>
  </header>

  <div class="d-flex flex-row row mt-3">
    <div class="col-12">
      <div class="card rounded-0">
        <div class="card-header">
          <h2 class="card-title">
            Field Group:
            <span style="font-style: italic; color: #b3b3b3">{{ fieldGroupName }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;Service Deployment:
            <span style="font-style: italic; color: #b3b3b3">
              {{ serviceDeploymentName }}&nbsp;({{ serviceDeploymentRefNumber }} )
            </span>
            <v-refresh-button class="d-inline-block ms-2" (refreshClick)="refresh()"></v-refresh-button>
          </h2>
        </div>
        <div class="card-body">
          <search-filter
            [unFilteredList]="cols"
            (searchBySubmitted)="searchName($event)"
            (clearClicked)="clearFilter($event)"
            [searchBy]="searchBy"
          ></search-filter>
          <v-loading-container [working]="loading" [spinnerColor]="'#dedede'">
            <p-table
              #dt
              [columns]="cols"
              [value]="data"
              [rows]="20"
              [totalRecords]="totalRecords"
              [responsive]="true"
              [lazy]="true"
              (onLazyLoad)="sortdata($event)"
              scrollable="true"
              scrollDirection="both"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th
                    [style]="{ width: '200px' }"
                    *ngFor="let col of columns"
                    [pSortableColumn]="col.field != 'serviceDeploymentTaskLocationCount' ? col.field : undefined"
                    [attr.data-cy]="'ser-dep-task-table-' + col.field + '-label'"
                  >
                    {{ col.header }}
                    <ng-container *ngIf="col.field != 'serviceDeploymentTaskLocationCount'">
                      <p-sortIcon class="p-sortable-column-icon" [field]="col.field"></p-sortIcon>
                    </ng-container>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td
                    [style]="{ width: '200px' }"
                    *ngFor="let col of columns"
                    [attr.data-cy]="'ser-dep-task-table-' + col.field"
                  >
                    <div
                      *ngIf="
                        col.field !== 'serviceDeploymentTaskLocationCount' &&
                        col.field !== 'suppliers' &&
                        col.field !== 'instructionUrls' &&
                        col.field !== 'referenceNumber' &&
                        col.field !== 'note'
                      "
                    >
                      {{ rowData[col.field] }}
                    </div>

                    <div *ngIf="col.field === 'referenceNumber'">
                      <a (click)="editServiceTask(rowData)" style="color: cornflowerblue; text-decoration: underline">
                        {{ rowData[col.field] }}
                      </a>
                    </div>

                    <button
                      type="button"
                      [ngClass]="rowData[col.field] == '0' ? 'btn btn-danger' : 'btn btn-success'"
                      class="btn btn-success"
                      *ngIf="col.field === 'serviceDeploymentTaskLocationCount'"
                      [routerLink]="['/servicedeployments', serviceDeploymentRefId, 'task', rowData['id']]"
                      (click)="passData(rowData)"
                      [style]="{ width: '80px' }"
                    >
                      {{ rowData[col.field] }}
                    </button>

                    <div *ngIf="col.field === 'suppliers' || col.field === 'instructionUrls'">
                      <div *ngIf="col.field === 'suppliers'">
                        <long-text-limit
                          [text]="getSupplierLabel(rowData[col.field])"
                          [title]="rowData['name'] + ' - Suppliers'"
                        ></long-text-limit>
                      </div>
                      <div *ngIf="col.field === 'instructionUrls'">
                        <div *ngFor="let instructionurl of rowData[col.field]">
                          <a [href]="instructionurl.url | secureUrl | async" target="_blank">
                            {{ instructionurl.name }}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="col.field == 'note'">
                      <long-text-limit
                        [text]="rowData[col.field]"
                        [title]="rowData['name'] + ' - Note'"
                      ></long-text-limit>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </v-loading-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <ngb-pagination
        [collectionSize]="totalRecords"
        class="d-flex justify-content-center"
        [pageSize]="servicedeployemntfilterParams.pageSize"
        [page]="servicedeployemntfilterParams.page"
        [maxSize]="5"
        [rotate]="true"
        [ellipses]="true"
        (pageChange)="pagination($event)"
      ></ngb-pagination>
    </div>
  </div>
</div>
