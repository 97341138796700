import { NgRedux, select } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocalStorageService } from '@volt/shared/services/local-storage.service';
import { PermissionNames } from '@volt/shared/services/permissionNames';
import { PermissionsService, Privilege } from '@volt/shared/services/permissions.service';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, of, SubscriptionLike as ISubscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import {
  AccountActivityClient,
  FileResponse,
  GenericTableReportOfServiceDeploymentTasksViewModel,
  RetailtainmentServiceEventTaskDto,
  ServiceTaskEventClient,
  ServiceTaskRetrievalClient,
} from '../../../../api.client';
import { RoleConstant } from '../../../../auth/shared/roles.constants';
import { CommonUtils } from '../../../../shared/utils/common.utils';
import { ServiceDeploymentStateConstant } from '../../../actions/servicedeployment.constants';
import { AddServiceTask } from '../../../models/addServiceTask';
import { ServiceDeploymentAndServiceTaskParams } from '../../../models/servicedeployment-and-servicetaskparams';
import { ServiceDeploymentReportParams } from '../../../models/servicedeployment-report-parameters';
import { ServicedeploymnetTaskSearchParameters } from '../../../models/servicedeploymnet-task-search-parameters';
import { serviceDeploymentsReportSelector } from '../../../reducers/service-deployments-report.reducer';
import { DataPassingService } from '../../../services/data-passing.service';
import { ServicedeploymentReduxService } from '../../../services/servicedeployment.redux.service';
import { AddServicedeploymentTaskReduxService } from '../../addservicedeploymenttask/services/add-servicedeploymenttask.redux.service';
import { ServiceDeploymentsService } from '../../servicedeployments/services/service-deployments.service';
import { TableDisplay } from '../../table-display/table-display.component';
import { ServicedeploymentTaskService } from '../services/servicedeployment-task.service';

@Component({
  selector: 'servicedeployemt-task',
  templateUrl: 'servicedeployment-task.component.html',
})
export class ServicedeploymentTaskComponent extends TableDisplay implements OnInit, OnDestroy {
  public serviceDeploymentRefId: string;
  public serviceDeploymentRefNumber: string;
  public fieldGroupName: string;
  public serviceDeploymentName: string;
  public statusType: string;
  public accountId: number;
  public serviceDeploymentAndServiceTask: ServiceDeploymentAndServiceTaskParams;
  public status;
  public currentServiceTask: AddServiceTask;
  public showDateFilter: boolean;
  public role = RoleConstant;

  private routeSubscription: ISubscription;
  private dataSubscription: ISubscription;
  private serviceDeploymentRefNumberSubscription: ISubscription;
  private advancedSearchParamsSubscription: ISubscription;

  canAdd = this._permissionsService.hasPermission(PermissionNames.SchedulesManage, Privilege.Create);

  @select([ServiceDeploymentStateConstant.FilterStateName])
  private readonly serviceDeploymentTask: Observable<ServiceDeploymentAndServiceTaskParams>;

  @select(serviceDeploymentsReportSelector)
  private readonly serviceDeploymentsReport$: Observable<ServiceDeploymentReportParams>;

  hasRetailtainmentActivity$: Observable<boolean>;

  constructor(
    private _route: ActivatedRoute,
    private _serviceDeploymentTaskService: ServicedeploymentTaskService,
    private _dataPassingService: DataPassingService,
    private _servicedeploymentReduxService: ServicedeploymentReduxService,
    private _router: Router,
    private _servicedeploymentTaskClient: ServiceTaskRetrievalClient,
    private _addServiceTaskReduxService: AddServicedeploymentTaskReduxService,
    private _serviceDeploymentsService: ServiceDeploymentsService,
    private _accountActivityClient: AccountActivityClient,
    private _serviceTaskEventClient: ServiceTaskEventClient,
    private readonly _permissionsService: PermissionsService,
    private readonly _localStorageService: LocalStorageService,
  ) {
    super(_serviceDeploymentsService);
  }

  ngOnInit() {
    this.serviceDeploymentsReport$.pipe(take(1)).subscribe(filters => {
      this.servicedeployemntfilterParams = filters;
      this.searchBy = 'Search By Task Name';
      this.showDateFilter = false;
      this.routeSubscription = this._route.params.subscribe((params: Params) => {
        this.serviceDeploymentRefId = params['refid'];
        this.servicedeployemntfilterParams = Object.assign(this.servicedeployemntfilterParams, {
          serviceDeploymentRefId: this.serviceDeploymentRefId,
        });
      });

      this.serviceDeploymentRefNumberSubscription = this.serviceDeploymentTask.subscribe(
        (data: ServiceDeploymentAndServiceTaskParams) => {
          this.initServiceDeploymentValuesFromParams(data);
        },
      );

      if (this.fieldGroupName == undefined || this.fieldGroupName === '' || this.fieldGroupName == null) {
        this._localStorageService.useLocalStorage();
        const params = this._localStorageService.getObject('ServiceDeploymentAndTaskParams');
        if (this.paramsExistAndHaveValidFields(params)) {
          this.initServiceDeploymentValuesFromParams(params);
          this._servicedeploymentReduxService.setFilter(params);
          this._localStorageService.remove('ServiceDeploymentAndTaskParams');
        } else {
          this._router.navigate(['/servicedeployments/data']);
        }
      }

      this.advancedSearchParamsSubscription = this._dataPassingService.advancedSearchParams.subscribe(
        servicedeploymentTaskSearchParameters => {
          if (servicedeploymentTaskSearchParameters) {
            const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
              servicedeploymentTaskSearchParameters: servicedeploymentTaskSearchParameters,
            });
            this.pullData(updateFilter);
          }
        },
      );
    });
  }

  public refresh() {
    this.servicedeployemntfilterParams.sortColumn = 'name';
    this.pullData(this.servicedeployemntfilterParams);
  }

  pullData($event: ServiceDeploymentReportParams) {
    this.loading = true;
    this.dataSubscription = this._serviceDeploymentTaskService
      .getServiceDeploymentTask($event)
      .subscribe((data: GenericTableReportOfServiceDeploymentTasksViewModel) => {
        this.cols = data.columnList;
        this.data = data.results;
        this.totalRecords = data.totalCount;
        this.loading = false;
      });
  }

  sortdata(event: LazyLoadEvent) {
    this.serviceDeploymentsReport$.pipe(take(1)).subscribe(filters => {
      this.servicedeployemntfilterParams = filters;
      if (event.sortField == undefined) event.sortField = 'name';

      let sortFieldwithOrdering = '';
      if (event.sortOrder === -1) {
        sortFieldwithOrdering = sortFieldwithOrdering.concat('-').concat(event.sortField);
      } else {
        sortFieldwithOrdering = sortFieldwithOrdering.concat(event.sortField);
      }

      const updateFilter = Object.assign({}, this.servicedeployemntfilterParams, {
        sortColumn: sortFieldwithOrdering,
      });
      this.pullData(updateFilter);
    });
  }

  ngOnDestroy() {
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.dataSubscription && this.dataSubscription.unsubscribe();
    this.serviceDeploymentRefNumberSubscription && this.serviceDeploymentRefNumberSubscription.unsubscribe();
    this.advancedSearchParamsSubscription && this.advancedSearchParamsSubscription.unsubscribe();
  }

  searchName(name: string) {
    name = name.trim();
    const updateServiceDeploymnetFilter = Object.assign(
      this.servicedeployemntfilterParams.servicedeploymentTaskSearchParameters,
      { nameSearch: name },
    );
    const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
      servicedeploymentTaskSearchParameters: updateServiceDeploymnetFilter,
    });
    this.pullData(updateFilter);
  }

  public passData(data: AddServiceTask) {
    const params = Object.assign(
      {},
      {
        serviceDeploymentTaskRefNumber: data.referenceNumber,
        status: data.status,
        serviceDeploymentName: this.serviceDeploymentName,
        serviceTaskName: data.name,
        fieldGroupName: this.fieldGroupName,
        serviceDeploymentRefNumber: this.serviceDeploymentRefNumber,
        accountId: this.accountId
      },
    );
    this._servicedeploymentReduxService.setFilter(params);
  }

  clearFilter(flag) {
    if (flag) {
      const updateFilter = Object.assign(this.servicedeployemntfilterParams, {
        servicedeploymentTaskSearchParameters: new ServicedeploymnetTaskSearchParameters(),
      });
      this.pullData(updateFilter);
    }
  }

  onDownLoad() {
    this.loading = true;
    this._servicedeploymentTaskClient.getFile(this.serviceDeploymentRefId).subscribe((file: FileResponse) => {
      const date = new Date();
      const cleanFieldGroupName = this.fieldGroupName.replace(/[^a-zA-Z ]/g, '');
      const fileName = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${cleanFieldGroupName}-${
        this.serviceDeploymentRefNumber
      }-tasks.csv`;
      CommonUtils.download(file.data, fileName);
      this.loading = false;
    });
  }

  public addServiceDeploymentTasks() {
    this.currentServiceTask = new AddServiceTask();
    this.currentServiceTask.add = true;
    this.currentServiceTask.edit = false;
    this._addServiceTaskReduxService.setFilter(this.currentServiceTask);
    this._router.navigate(['/servicedeployments', 'addservicedeploymenttask', this.serviceDeploymentRefId]);
  }

  public editServiceTask(data: AddServiceTask) {
    data.add = false;
    data.edit = true;
    data.accountId = this.accountId;
    this._addServiceTaskReduxService.setFilter(data);
    if (data.activity !== 'Retailtainment') {
      this._router.navigate(['/servicedeployments', 'addservicedeploymenttask', this.serviceDeploymentRefId]);
    } else {
      this._serviceTaskEventClient
        .getSingleEvent(data['id'])
        .pipe(
          catchError(() => {
            return of(null);
          }),
        )
        .subscribe((task: RetailtainmentServiceEventTaskDto) => {
          if (task) {
            this._router.navigate(['/servicedeployments/retailtainment-task/edit', task.id]);
          } else {
            this._router.navigate(['/servicedeployments', 'addservicedeploymenttask', this.serviceDeploymentRefId]);
          }
        });
    }
  }

  addRetailtainmentEvent() {
    this._router.navigate([
      '/servicedeployments/retailtainment-task/create',
      this.serviceDeploymentRefId,
      this.accountId,
      this.serviceDeploymentRefNumber,
    ]);
  }

  getSupplierLabel(data: any[]): string {
    return data.map(s => `${s.name}, ${s.number}`).join('\n');
  }

  private initServiceDeploymentValuesFromParams(params: ServiceDeploymentAndServiceTaskParams) {
    this.serviceDeploymentRefNumber = params.serviceDeploymentRefNumber;
    this.fieldGroupName = params.fieldGroupName;
    this.statusType = params.status;
    this.accountId = params.accountId;
    this.serviceDeploymentName = params.serviceDeploymentName;
    this.serviceDeploymentAndServiceTask = params;
    this.hasRetailtainmentActivity$ = this._accountActivityClient.hasRetailtainmentActivity(this.accountId);
  }

  private paramsExistAndHaveValidFields(params: ServiceDeploymentAndServiceTaskParams): boolean {
    return !(
      params === null ||
      !params.fieldGroupName ||
      !params.serviceDeploymentRefNumber ||
      !params.serviceDeploymentName
    );
  }
}
