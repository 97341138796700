import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DropDownModel } from '../../../../../api.client';
import { FieldsInfoModalComponent } from '../../../fields-info-modal/fields-info-modal.component';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'status-list',
  templateUrl: './status.component.html',
  styleUrls: ['../../addservicedeployments.component.scss'],
})
export class StatusComponent implements OnInit {
  @Input() public statusDropDownModel: DropDownModel[];
  @Input() public group: UntypedFormGroup;
  @Input() public submitted;
  @Input() public selectedStatus;

  ngOnInit(): void {}

  constructor(private _dynamicDialogService: DynamicDialogService) {}

  public showInfo() {
    this._dynamicDialogService.open(FieldsInfoModalComponent, {
      data: {
        messages: [
          'Setting it Inactive is for when Service Deployment is cancelled and will no longer be executed.\n' +
            'Do not set a Service Deployment to Inactive just because it was completed.',
        ],
      },
    });
  }

  public get statusControl(): UntypedFormControl {
    if (!this.group) {
      return;
    }

    return <UntypedFormControl>this.group.get('serviceStatus');
  }
}
